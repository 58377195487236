import React, { useState, useRef, useEffect } from "react";
import enLogo from "../../../assets/logos/en-logo.png";
import arLogo from "../../../assets/logos/ar-logo.png";
import arLogoClub from "../../../assets/logos/ar-logo-club.png"
import enLogoClub from "../../../assets/logos/en-logo-club.png"
import { BiSearch } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
// import ShoppingCart from "./ShoppingCart";
// import CurrencyChooser from "./CurrencyChooser";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RxHamburgerMenu } from "react-icons/rx";
import i18n from "../../translate/i18n"

function NavigationBar() {

    const { t, i18n } = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang);
    };

    const currentLanguage = i18n.language;

    const navigate = useNavigate();

    const [showInput, setShowInput] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showDropdownClub, setShowDropdownClub] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);


    const inputRef = useRef(null);

    const location = useLocation();

    const logoSrc = currentLanguage === "en" 
    ? (location.pathname.includes("club") ? enLogoClub : enLogo) 
    : (location.pathname.includes("club") ? arLogoClub : arLogo);


    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width < 640);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handleSearchIconClick = () => {
        setShowInput(true);
        setTimeout(() => {
            inputRef.current.focus();
        }, 100);
    };

    const handleBlur = () => {
        setShowInput(false);
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };



    return (
        <div className={`bg-white/30 z-[20] w-[100%] h-[50px] hd:h-[80px] fhd:h-[110px] 2k:h-[140px] flex ${currentLanguage==="ar" ? "font-hacenCasablanca" : "font-amatic"} ${currentLanguage === "ar" && "flex-row-reverse"}`}>
            {isMobile &&<RxHamburgerMenu onClick={toggleSidebar} className={`text-black text-[20px] cursor-pointer my-auto ${currentLanguage === "en" ? "ml-[20px]" : "mr-[20px]"}`} />}
            <img loading="lazy" alt="upla logo" src={logoSrc} className={`h-[70%] max-sm:h-[60%] mt-auto mb-auto ${currentLanguage === "en" ? "max-sm:ml-[20px] hd:ml-[60px] fhd:ml-[80px] 2k:ml-[100px]" : "max-sm:mr-[20px] hd:mr-[60px] fhd:mr-[80px] 2k:mr-[100px]"}  cursor-pointer hover:opacity-75`} />
            <BiSearch className={`fhd:text-[32px] max-sm:opacity-0 hd:text-[26px] mt-[2.3%] ${currentLanguage === "en" ? "ml-[30px]" : "mr-[30px]"} hover:opacity-75 cursor-pointer z-10 ${showInput ? "text-black" : "text-black"}`} onClick={handleSearchIconClick} />
            {showInput && (
                <input
                    ref={inputRef}
                    type="text"
                    placeholder={currentLanguage === "en" ? "Search anything..." : "ابحث عن اي شيء..."}
                    className={`search-input ${showInput ? "search-input-appear" : "search-input-disappear"} ${currentLanguage === "en" ? "ml-[-40px] pl-[52px]" : "mr-[-36px] pr-[42px]"} shadow-lg h-[40%] fhd:w-[304px] hd:w-[200px] mt-[1.9%]  text-[${process.env.REACT_APP_UPLA_TEXT_COLOR}]  rounded-[11px] fhd:text-[18px] hd:text-[12px] font-bold outline-none`}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    onBlur={handleBlur}
                />
            )}
            {!isMobile && <ul className={`flex hd:gap-x-10 max-sm:opacity-0 mt-auto mb-auto  2k:gap-x-[100px] fhd:gap-x-10 ${currentLanguage === "ar" ? "flex-row-reverse hd:mr-[30px] fhd:mr-[40px] 2k:mr-[50px] " : "hd:ml-[30px] fhd:ml-[40px] 2k:ml-[50px]"}`}>
                <li onClick={() => { navigate("/home") }} className={`relative font-bold 2k:text-[24px] fhd:text-[24px]  mt-[6px] cursor-pointer ${currentLanguage==="ar" && "font-hacenCasablanca"} ${location.pathname.includes("home") ? "text-[#FB7F0D]" : "text-black hover:text-[#FB7F0D]"} group`}>
                    {t('home')}
                    <span className={`absolute  left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50px] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D]   ${location.pathname.includes("home") ? "scale-x-100" : "scale-x-0 group-hover:scale-x-100"} transition-transform duration-300 ease-in-out origin-left`}></span>
                </li>
                <li onMouseEnter={() => setShowDropdownClub(true)} onMouseLeave={() => setShowDropdownClub(false)} className={`relative flex font-bold  2k:text-[24px] fhd:text-[24px] ${currentLanguage==="ar" ? "font-hacenCasablanca" : "font-amatic"} mt-[6px] cursor-pointer ${location.pathname.includes("club") ? "text-[#FB7F0D]" : "text-black hover:text-[#FB7F0D]"}  group`}>
                    {t('club')}
                    <IoIosArrowDown className="fhd:text-[24px] hd:text-[18px] mt-[12%] ml-[3%]" />
                    <span className={`absolute  left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50px] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D]   ${location.pathname.includes("club") ? "scale-x-100" : "scale-x-0 group-hover:scale-x-100"} transition-transform duration-300 ease-in-out origin-left`}></span>
                    {showDropdownClub && (
                        <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`absolute mt-[30px] z-20 ${currentLanguage === "ar" && "right-[0.8rem] "}  w-[140px] rounded-md shadow-lg ${currentLanguage==="ar" ? "font-hacenCasablanca" : "font-amatic"} bg-white ring-1 ring-black ring-opacity-5`}>
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <Link to="/club" dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="block  px-4 py-2 text-[20px] text-gray-700 hover:bg-gray-100 hover:text-gray-900 " role="menuitem">{currentLanguage === "en" ? "Club" : "النادي"}</Link>
                                <Link to="/club/about" dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="block px-4 py-2 text-[20px] text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{currentLanguage === "en" ? "About The Club" : "حول النادي"}</Link>
                                <Link to="/club/form" dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="block px-4 py-2 text-[20px] text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{currentLanguage === "en" ? "Club Form" : "استمارة النادي"}</Link>
                            </div>
                        </div>
                    )}
                </li>

                {/* <li className="relative font-bold text-black 2k:text-[24px] fhd:text-[24px]  mt-[6px] hover:text-[#FB7F0D] cursor-default group">
                    {t('allProducts')}
                    <div className="absolute font-amatic w-[110%] h-[110%] bg-gray-200/75 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left top-0 left-[-5%] fhd:text-[18px] hd:text-[12px] text-[#FB7F0D] pt-[8%]  text-center">Coming Soon</div> */}
                    {/* <span className="absolute left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50px] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span> */}
                {/* </li>
                <li className="relative flex font-bold text-black 2k:text-[24px] fhd:text-[24px]  mt-[6px] cursor-default hover:text-[#FB7F0D] group">
                    {t('books')}
                    <div className="absolute font-amatic w-[200%] h-[110%] bg-gray-200/75 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left top-0 left-[-50%] fhd:text-[18px] hd:text-[12px] text-[#FB7F0D] pt-[16%] text-center">Coming Soon</div> */}
                    {/* <IoIosArrowDown className="text-[24px] mt-[12%] ml-[2%]" />
                    <span className="absolute left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50px] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span> */}
                {/* </li>
                <li className="relative font-bold text-black 2k:text-[24px] fhd:text-[24px]  mt-[6px] cursor-default hover:text-[#FB7F0D] group">
                    {t('educationalGames')}
                    <div className="absolute font-amatic w-[110%] h-[110%] bg-gray-200/75 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left top-0 left-[-5%] fhd:text-[18px] hd:text-[12px] text-[#FB7F0D] pt-[5%] text-center">Coming Soon</div> */}
                    {/* <span className="absolute left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50pxعن نادينا] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span> */}
                {/* </li>
                <li className="relative font-bold text-black 2k:text-[24px] fhd:text-[24px]  mt-[6px] cursor-default hover:text-[#FB7F0D] group">
                    {t('gifts')}
                    <div className={`absolute font-amatic ${currentLanguage === "ar" ? "w-[180%] left-[-50%]" :"w-[230%] left-[-70%]"}  h-[110%] bg-gray-200/75 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left top-0  fhd:text-[18px] hd:text-[12px] text-[#FB7F0D] pt-[16%] text-center`}>Coming Soon</div> */}
                    {/* <span className="absolute  left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50px] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span> */}
                {/* </li>
                <li className="relative font-bold text-black 2k:text-[24px] fhd:text-[24px]  mt-[6px] cursor-default hover:text-[#FB7F0D] group">
                    {t('printables')}
                    <div className="absolute font-amatic w-[110%] h-[110%] bg-gray-200/75 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left top-0 left-[-5%] fhd:text-[18px] hd:text-[12px] text-[#FB7F0D] pt-[8%] text-center">Coming Soon</div> */}
                    {/* <span className="absolute  left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50px] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span> */}
                {/* </li>
                <li className={`relative font-bold text-black 2k:text-[24px] fhd:text-[24px]  mt-[6px] cursor-default hover:text-[#FB7F0D] group`}>
                    {t("blog")}
                    <div className={`absolute font-amatic h-[110%] bg-gray-200/75 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left top-0 ${currentLanguage === "ar" ? "left-[-40%] w-[180%]" : "w-[270%] left-[-80%]"}  fhd:text-[18px] hd:text-[12px] text-[#FB7F0D] pt-[16%] text-center`}>Coming Soon</div> */}
                    {/* <span className="absolute  left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50px] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D] scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span> */}
                {/* </li> */}
                <li onClick={() => { navigate("/expressfeelings") }} className={`relative font-bold 2k:text-[24px] fhd:text-[24px]  mt-[6px] cursor-pointer ${location.pathname.includes("expressfeelings") ? "text-[#FB7F0D]" : "text-black hover:text-[#FB7F0D]"} group`}>
                    {t('expressYourFeling')}
                    <span className={`absolute  left-0 fhd:bottom-[-34px] hd:bottom-[-25px] 2k:bottom-[-50px] w-full fhd:h-[7px] hd:h-[5px] 2k:h-[9px] bg-[#FB7F0D] ${location.pathname.includes("expressfeelings") ? "scale-x-100" : "scale-x-0 group-hover:scale-x-100"} transition-transform duration-300 ease-in-out origin-left`}></span>
                </li>

            </ul>}
            {isMobile && showSidebar && (
                <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`${currentLanguage === "ar" ? "right-0" : "left-0"} fixed top-0  w-[250px] h-full bg-white shadow-lg z-[30] transition-transform transform ${showSidebar ? "translate-x-0" : "-translate-x-full"} `}>
                    <IoMdClose
                        onClick={() => setShowSidebar(false)} 
                        className={`text-[24px] text-red-600 cursor-pointer absolute top-4  ${currentLanguage === "ar" ? "left-4" : "right-4"}`}
                    />
                    <ul className="p-4">
                        <li onClick={() => navigate("/home")} className={`relative w-fit-content font-bold text-[14px]  mt-[6px] select-none cursor-pointer ${location.pathname.includes("home") ? "text-[#FB7F0D]" : "text-black hover:text-[#FB7F0D]"}`}>
                            {t('home')}
                        </li>
                        <li onMouseEnter={() => setShowDropdownClub(true)} onMouseLeave={() => setShowDropdownClub(false)} className={`relative w-fit-content select-none flex font-bold  text-[14px]  mt-[6px] cursor-pointer ${location.pathname.includes("club") ? "text-[#FB7F0D]" : "text-black hover:text-[#FB7F0D]"}  group`}>
                            {t('club')}
                            <IoIosArrowDown className="text-[14px] mt-[4%] ml-[5%]" />
                            {showDropdownClub && (
                                <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={` mt-[30px] ${currentLanguage === "ar" && "ml-auto"}  w-fit-content`}>
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        <Link to="/club" dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="block  whitespace-nowrap px-4 py-2 text-[12px] text-gray-700 hover:bg-gray-100 hover:text-gray-900 " role="menuitem">{currentLanguage === "en" ? "Club" : "النادي"}</Link>
                                        <Link to="/club/about" dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="block  whitespace-nowrap px-4 py-2 text-[12px] text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{currentLanguage === "en" ? "About The Club" : "حول النادي"}</Link>
                                        <Link to="/club/form" dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="block  whitespace-nowrap px-4 py-2 text-[12px] text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{currentLanguage === "en" ? "Club Form" : "استمارة النادي"}</Link>
                                    </div>
                                </div>
                            )}
                        </li>
                        <li onClick={() => navigate("/expressfeelings")} className={`relative w-fit-content font-bold text-[14px] select-none  mt-[6px] cursor-pointer ${location.pathname.includes("expressfeelings") ? "text-[#FB7F0D]" : "text-black hover:text-[#FB7F0D]"}`}>
                            {t('expressYourFeling')}
                        </li>
                    </ul>
                </div>
            )}
            <div className={`absolute 2k:mt-[54px] fhd:mt-[37px] hd:mt-[30px] max-sm:mt-[12px] flex flex-row ${currentLanguage === "en" ? "max-sm:right-[4%] font-hacenCasablanca fhd:right-[5%] hd:right-[3%]" : "max-sm:left-[4%] font-amatic fhd:left-[5%] hd:left-[3%]"} font-bold text-black 2k:text-[24px] fhd:text-[24px]  mt-[6px] `}>
                <span className="cursor-pointer hover:text-[#FB7F0D]" onClick={() => {
                    if (currentLanguage === 'en') {
                        changeLanguage('ar');
                    } else {
                        changeLanguage('en');
                    }
                }}>{t('language')}</span>
                {/* <ShoppingCart />
                <CurrencyChooser /> */}
            </div>

        </div>
    )
}

export default React.memo(NavigationBar);
