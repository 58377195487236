import React ,{useEffect, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaWpforms } from "react-icons/fa";
import logo from "../assets/logos/en-footer-logo.png"
import useUserContext from '../contexts/user/useUserContext';
import { PiSignOutLight } from "react-icons/pi";
import { MdRateReview } from "react-icons/md";


export default function SharedPage({children,path}) {

    const navigate = useNavigate();

    const location = useLocation();

    const [isFormPath, setIsFormPath] = useState(false);

    const [isNotePath , setIsNotePath] = useState(false)

    const { currentUser,setCurrentUser } = useUserContext();

    useEffect(() => {
        if(!sessionStorage.getItem("uid")) {
            navigate("/home")
        }
    },[]);

    useEffect(() => {
        if (location.pathname.includes("forms")) {
            setIsFormPath(true);
        }
        else {
            setIsFormPath(false);
        }
        if (location.pathname.includes("note")) {
            setIsNotePath(true);
        }
        else {
            setIsNotePath(false);
        }
    }, [path]);


    const navigateTo = (path) => {
        navigate(path);
    }

    function firstLetter(str) {
        return str.charAt(0);
    }

    const handleLogout = () => {
        setCurrentUser(null);
        navigate('/login');
    }



    return (
        <body className='w-full h-full bg-white'>
            <div className='w-full h-[10vh] flex bg-white p-4 shadow-md mb-[8px] z-10 '>
                <div className={`font-maven-pro font-black text-white text-[4vh] mt-auto mb-auto 
                transition-all duration-500 ease-in-out`}>
                    <img loading="lazy" src={logo} className='w-[4vw]'/>
                </div>
                {currentUser!=null && <div className='w-[3vw] h-[6vh] rounded-full bg-[#4874ED] mb-auto mt-auto ml-auto text-white font-maven-pro font-black text-[3vh] text-center pt-1 cursor-pointer hover:opacity-75'>
                    {firstLetter(currentUser?.nom) + firstLetter(currentUser?.prenom)}
                </div>}
            </div>
            <div className='flex flex-col'>
                <div className={`w-[5vw] h-[90vh] bg-[#57BEA4] pt-[3vh] mt-[-8px]`}>
                    <div className={`cursor-pointer w-full h-[10vh]
                    flex ${isFormPath? '' : ""}`} onClick={() => navigateTo("/admin/forms")}>
                        <div className={`flex h-[65%] w-[65%] hover:bg-white/35 m-auto rounded-[12px] ${isFormPath && 'bg-white/35'}`}>
                            <FaWpforms className={`text-[3vh] text-white m-auto `}/>
                        </div>
                    </div>
                    <div className={`cursor-pointer w-full h-[10vh]
                    flex ${isNotePath? '' : ""}`} onClick={() => navigateTo("/admin/note")}>
                        <div className={`flex h-[65%] w-[65%] hover:bg-white/35 m-auto rounded-[12px] ${isNotePath && 'bg-white/35'}`}>
                            <MdRateReview className={`text-[3vh] text-white m-auto `}/>
                        </div>
                    </div>
                    {currentUser!=null &&<div className={`cursor-pointer w-full h-[10vh]
                    flex mt-[45vh]`} onClick={handleLogout}>
                        <div className={`flex h-[65%] w-[65%] hover:bg-white/35 m-auto rounded-[12px] `}>
                            <PiSignOutLight className={`text-[4vh] text-white m-auto`}/>
                        </div>
                    </div>}
                </div>
                <div className={` overflow-auto h-[89vh] w-[95vw] flex fixed flex-wrap bg-white ml-[5vw] `}>
                    {children}
                </div>
            </div>
        </body>
    );
}