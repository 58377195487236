import React, { useEffect, useState } from "react";
import Titles from "../Titles";
import { useTranslation } from "react-i18next";

export default function RegistrationsInfo() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const [data, setData] = useState()

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubInformation/get/${currentLanguage}`)
            .then(response => response.json())
            .then(data => {
                setData(data);
            })

    }, [localStorage.getItem("language")]);


    return (
        <div className={`bg-[#58C2A6] mt-[10vh] flex flex-col ${currentLanguage==="ar" ? "font-nokta" : "font-amatic"} ${currentLanguage === "ar" && "flex-row-reverse"}`}>
            <Titles color="#FB7F0D" title={t("price")} margin="mt-[4vh] max-sm:mt-[3vh]" />
            {data && <> <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`text-white ${currentLanguage === "ar" ? "mr-[7%]" : "ml-[7%]"}  font-bold 2k:text-[72px] fhd:text-[64px] hd:text-[48px] max-sm:text-[16px] mt-[4vh] `}>{data.semesterTitle} {data.semesterDuration} </h1>
                {/* <div className={`flex w-[90%] mx-auto mt-[4vh] max-sm:mt-[1vh] ${currentLanguage === "ar" ? "flex-row-reverse mr-[7%]" : "flex-row ml-[7%]"}`}>
                    <h1 className={`text-white  2k:text-[76px] fhd:text-[64px] hd:text-[52px] ${currentLanguage === "ar" ? "ml-auto" : "mr-auto"} `}>
                        {t("registrationFee")}
                    </h1>
                    <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`text-white  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] ${currentLanguage === "ar" ? "mr-auto ml-[7%]" : "ml-auto mr-[7%]"}`}>
                        {data.registrationFee} {currentLanguage === "ar" ? "سنة" : "year"}  
                    </h1>
                </div>
                <div className={`flex w-[90%] mx-auto mt-[4vh] max-sm:mt-[1vh] ${currentLanguage === "ar" ? "flex-row-reverse mr-[7%]" : "flex-row ml-[7%]"}`}>
                    <h1 className={`text-white  2k:text-[76px] fhd:text-[64px] hd:text-[52px] ${currentLanguage === "ar" ? "ml-auto" : "mr-auto"} `}>
                        {t("oneSession")}
                    </h1>
                    <h1 className={`text-white  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] ${currentLanguage === "ar" ? "mr-auto ml-[7%]" : "ml-auto mr-[7%]"}`}>
                        {data.sessionFee}
                    </h1>
                </div> */}
                <div className={`flex w-[90%] mx-auto mt-[4vh] max-sm:mt-[1vh] ${currentLanguage === "ar" ? "flex-row-reverse mr-[7%]" : "flex-row ml-[7%]"}`}>
                    <h1 className={`text-white  2k:text-[76px] fhd:text-[64px] hd:text-[52px] ${currentLanguage === "ar" ? "ml-auto" : "mr-auto"} `}>
                        {t("semesterFee")} {t("registrationFeeFree")}
                    </h1>
                    <h1 className={`text-white  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] ${currentLanguage === "ar" ? "mr-auto ml-[7%]" : "ml-auto mr-[7%]"}`}>
                        {data.semesterFee}
                    </h1>
                </div>
                <div className={`flex w-[90%] mx-auto mt-[4vh] max-sm:mt-[1vh] ${currentLanguage === "ar" ? "flex-row-reverse mr-[7%]" : "flex-row ml-[7%]"}`}>
                    <h1 className={`text-white  2k:text-[76px] fhd:text-[64px] hd:text-[52px] ${currentLanguage === "ar" ? "ml-auto" : "mr-auto"} `}>
                        {t("trialSession")}
                    </h1>
                    <h1 className={`text-white  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] ${currentLanguage === "ar" ? "mr-auto ml-[7%]" : "ml-auto mr-[7%]"}`}>
                        {data.trialSession}
                    </h1>
                </div>
            </>}
            <Titles color="#FB7F0D" title={t("specialOffer")} margin="mt-[4vh] max-sm:mt-[1vh]" />
            <h1 className="text-white  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] ml-auto mr-auto mt-[6vh] max-sm:mt-[1vh] mb-[10vh] max-sm:mb-[3vh]">{t("specialOfferText")}</h1>
        </div>
    )
}