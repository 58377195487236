import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("language") || "ar");
  }, [i18n]);

  return <>{children}</>;
};

export default LanguageProvider;