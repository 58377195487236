import React, { useEffect, useState } from "react";
import SpecialOffers from "../utils/components/SpecialOffers";
import NavigationBar from "../utils/components/navigation/NavigationBar";
import TopImage from "../utils/components/TopImage";
import background from "../assets/club/topImage.svg"
import Footer from "../utils/components/footer/Footer";
import CopyRight from "../utils/components/footer/CopyRight";
import HomePageTitle from "../utils/components/HomePageTitle";
import LoveNotes from "../utils/components/LoveNotes";
import InputField from "../utils/components/club/InputField";
import TextArea from "../utils/components/club/TextArea";
import Button from "../utils/components/Button";
import yellowStar from "../assets/rating/yellow-star.svg";
import grayStar from "../assets/rating/gray-star.svg";
import animationData from "../assets/animation.mp4"
import { useTranslation } from 'react-i18next';

export default function ExpressFeelingsPage() {

    const { t, i18n } = useTranslation();

    const [showSuccess, setShowSuccess] = useState(false);

    const [name, setName] = useState("")
    const [textNote, setTextNote] = useState("")

    const [rating, setRating] = useState(0);

    const [note, setNote] = useState({
        customerName: "",
        customerText: "",
        customerRating: 0
    }
    )

    const [shake, setShake] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const currentLanguage = i18n.language;


    const handleOnChange = (event) => {
        const { id } = event.target;

        const newErrors = { ...errors, [id]: false };

        switch (event.target.id) {
            case 'name':
                setName(event.target.value);
                setNote({
                    ...note,
                    customerName: event.target.value
                })
                break;
            case 'textNote':
                setTextNote(event.target.value);
                setNote({
                    ...note,
                    customerText: event.target.value
                })
                break;
            default:
                break;
        }
        setErrors(newErrors);
    };


    const handleSubmit = () => {
        const newErrors = {};

        if (!textNote) newErrors.textNote = true;

        setErrors(newErrors);

        const isFormValid = Object.keys(newErrors).length === 0;
        if (isFormValid) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customerNote/addCustomerNote`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(note),
            })
                .then(response => response.json())
                .then(data => {
                    setName("");
                    setTextNote("");
                    setRating(0);
                    setShowSuccess(true);
                    setTimeout(() => setShowSuccess(false), 8000);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            setShake(true);
            setTimeout(() => setShake(false), 500);
        }
    };





    return (
        <div className="w-full h-auto overflow-x-hidden">
            {showSuccess && (<div className="fixed w-[100vw] h-[100vh] bg-black/75 z-20 flex flex-col">
                <video
                    className="mx-auto mt-auto w-[300px] h-[300px] rounded-full object-cover rounded-[10px] cursor-pointer"
                    src={animationData}
                    alt="Done"
                    autoPlay
                    muted={true}
                    playsInline
                    controls={false}
                    style={{ pointerEvents: "none" }}
                />
                <h1 className="font-amatic mx-auto mb-auto font-bold text-[64px] text-green-400 slide-in">{t("noteAdded")}</h1>
            </div>)}
            <SpecialOffers />
            <NavigationBar />
            <TopImage>
                <img loading="lazy" alt="what do you think about upla" src={background} className="absolute object-cover sm:h-[75vh] max-sm:h-[15rem] w-full" />
                <div className={`fhd:w-[34rem] hd:w-[26rem] text-center fhd:pt-[13rem] hd:pt-[10rem] max-sm:mt-[2.5rem] max-sm:h-[10rem] max-sm:w-[10rem] max-sm:pt-[3.4rem]  pl-[2rem] pr-[2rem] fhd:h-[34rem] hd:h-[26rem] bg-[#FFE358]/50 z-[2] ${ currentLanguage === "ar" && "flex-row-reverse"} m-auto rounded-full`}>
                    <h1 className="font-amatic font-bold text-stroke text-stroke-gray max-sm:w-[6rem] text-white sm:text-[40px]  ml-auto mr-auto mb-auto">{t("expressUpla")}</h1>
                </div>
            </TopImage>
            <HomePageTitle title={t("expressYourFeling")} />
            <div className="w-[60%] max-sm:w-[80%] mx-auto gap-y-20 mt-[10vh] mb-[10vh]">
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.name ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    type="text"
                    width={"w-[100%]"}
                    state={name}
                    placeHolder={t("name")}
                    id="name"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.name ? 'text-red-700 ' : 'text-black'}`}
                    dir={ currentLanguage === "ar" ? "rtl" : "ltr"}
                />

                <TextArea
                    className={`rounded-[15px] bg-white  focus:bg-white ${errors.textNote ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    width={"w-[100%]"}
                    type="text"
                    state={textNote}
                    placeHolder={t("expressYourFeling")}
                    dir={ currentLanguage === "ar" ? "rtl" : "ltr"}
                    id="textNote"
                    handleOnChange={handleOnChange}
                />
                <div className={`flex flex-row ${ currentLanguage === "ar" && "flex-row-reverse"}`}>
                    <Button
                        title={t("addNote")}
                        className={`z-[2]  mt-[10vh] max-sm:mt-[1vh] w-[12vw] max-sm:w-[7rem] max-sm:text-[10px] rounded-full  ${ currentLanguage === "en" ? "tracking-[10px] max-sm:tracking-[5px] mr-auto" : "tracking-[4px] max-sm:tracking-[2px] ml-auto"} ${shake ? 'animate-shake' : ''}`}
                        onClick={handleSubmit}
                    />
                    <div dir={ currentLanguage === "ar" ? "rtl" : "ltr"} className={`flex my-auto z-10 ${ currentLanguage === "en" ? "ml-auto" : "mr-auto"}`}>
                        {[...Array(5)].map((star, i) => {
                            const ratingValue = i + 1;
                            return (
                                <label
                                    key={i}
                                    onClick={() => {
                                        if (rating === i + 1) {
                                            setRating(0);
                                            setNote({
                                                ...note,
                                                customerRating: 0
                                            })
                                            return;
                                        }
                                        setRating(i + 1);
                                        setNote({
                                            ...note,
                                            customerRating: i + 1
                                        })
                                    }}
                                    className="cursor-pointer max-sm:w-[1rem] w-fit-content h-fit-content"
                                >                                    {ratingValue <= rating ? (
                                    <img loading="lazy" src={yellowStar} />
                                ) : (
                                    <img loading="lazy" src={grayStar} />
                                )}
                                </label>
                            )
                        })}
                    </div>
                </div>
            </div>
            <LoveNotes />
            <Footer />
            <CopyRight />
        </div>
    )
}