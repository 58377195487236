import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SharedPage from "./SharedPage";
import { useTranslation } from "react-i18next";
import InputField from "../utils/components/club/InputField";
import TextArea from "../utils/components/club/TextArea";
import Button from "../utils/components/Button";

export default function AdminFormDetails() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;


    const [data, setData] = useState({});

    const { id } = useParams();

    const [isDataUpdated, setIsDataUpdated] = useState(false);


    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubForm/get/${id}`, {
            method: 'GET', 
            headers: {
              'Content-Type': 'application/json',
              'X-UID': sessionStorage.getItem("uid") 
            }
          })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
            });
    }, [id]);

    const handleClick = () => {
        setData(prevData => ({
            ...prevData,
            paid: true
        }));
        setIsDataUpdated(true);
    };

    useEffect(() => {
        if (isDataUpdated) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubForm/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-UID': sessionStorage.getItem("uid")
                },
                body: JSON.stringify( data ),
            })
                .then((res) => res.json())
                .then((updatedData) => {
                    setData(updatedData); 
                    setIsDataUpdated(false); 
                });
        }
    }, [data, id, isDataUpdated])

    


    return (
        <SharedPage>
            <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="m-auto w-[70%] mb-[4%] flex flex-col">
                <h1 className="font-amatic font-bold fhd:text-[40px] hd:text-[34px] text-[#323E48]  mt-[6vh]">{t("guardian")}</h1>
                <div className={`flex flex-row gap-x-[30px]`}>
                    <InputField
                        className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                        type="text" width={"w-[50%]"}
                        state={data.guardianFirstName}
                        placeHolder={t("firstName")}
                        id="guardianFirstName"
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        readOnly={true}
                    />
                    <InputField
                        className={`rounded-[15px]  bg-white focus:bg-white border-[1px] border-black`}
                        type="text" width={"w-[50%]"}
                        state={data.guardianSecondName}
                        placeHolder={t("lastName")}
                        id="guardianSecondName"
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        readOnly={true}
                    />
                </div>
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    type="text"
                    width={"w-[50%]"}
                    state={data.email}
                    placeHolder={t("email")}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    id="email"
                    readOnly={true}

                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    type="text"
                    width={"w-[50%]"}
                    state={data.phoneNumber}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("phoneNumber")}
                    id="phoneNumber"
                    readOnly={true}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    type="text"
                    width={"w-[100%]"}
                    state={data.adress}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("adress")}
                    id="adress"
                    readOnly={true}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    type="text"
                    width={"w-[100%]"}
                    state={data.city}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("city")}
                    id="city"
                    readOnly={true}
                />
                <h1 className="font-amatic font-bold fhd:text-[40px] hd:text-[34px] text-[#323E48]  mt-[6vh]">{t("child")}</h1>
                <div className="flex flex-row gap-x-[30px]">
                    <InputField
                        className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                        type="text"
                        width={"w-[50%]"}
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        state={data.childFirstName}
                        placeHolder={t("firstName")}
                        id="childFirstName"
                        readOnly={true}
                    />
                    <InputField
                        className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                        type="text"
                        width={"w-[50%]"}
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        state={data.childSecondName}
                        placeHolder={t("lastName")}
                        id="childSecondName"
                        readOnly={true}
                    />
                </div>
                <InputField
                    className={`rounded-[15px] border-[1px] border-black text-center`}
                    type="number"
                    width={"w-[50%]"}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    state={data.age}
                    placeHolder={t("age")}
                    id="age"
                    readOnly={true}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    width={"w-[50%]"}
                    type="date"
                    state={data.birthDay}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("birthday")}
                    id="birthDay"
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    width={"w-[50%]"}
                    type="text"
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    state={data.schoolName}
                    placeHolder={t("schoolName")}
                    id="schoolName"
                    readOnly={true}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    width={"w-[50%]"}
                    type="text"
                    state={data.grade}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("grade")}
                    id="grade"
                    readOnly={true}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    width={"w-[50%]"}
                    type="text"
                    state={data.feePrice}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("paymentFee")}
                    id="grade"
                    readOnly={true}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    width={"w-[50%]"}
                    type="text"
                    state={data.registrationDate}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("regisrtationYear")}
                    id="grade"
                    readOnly={true}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    width={"w-[50%]"}
                    type="text"
                    state={data.registrationType}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("registrationType")}
                    id="grade"
                    readOnly={true}
                />
                <h1 className={`font-amatic font-bold fhd:text-[40px] hd:text-[34px] text-[#323E48] mt-[2%] ${data.socialMediaConsent ? "text-green-600" : "text-red-700"}`}>{data.socialMediaConsent ? t("youCanShare") : t("youCantShare")}</h1>
                <TextArea
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    width={"w-[100%]"}
                    type="text"
                    state={data.aboutChild === "" ? t("ntgToShow") : data.aboutChild}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("tellUs")}
                    id="aboutChild"
                    readOnly={true}
                />
                {!data.paid &&<Button
                    title={t("pay")}
                    className={`z-[2] ml-auto mr-auto mt-[5vh] w-[10vw] rounded-full tracking-[10px]`} 
                    onClick={handleClick}
                />}
            </div>
        </SharedPage>
    )
}