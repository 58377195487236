import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function TimeInfo() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const [data, setData] = useState()

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubInformation/get/${localStorage.getItem("language")? localStorage.getItem("language"): "en"}`)
            .then(response => response.json())
            .then(data => {
                setData(data);
            })

    }, [localStorage.getItem("language")]);

    return (
        <div className={`flex flex-row w-[70%] mx-auto mt-[10vh] max-sm:mt-[2vh] ${currentLanguage==="ar" ? "font-nokta" : "font-amatic"} ${currentLanguage==="ar" && "flex-row-reverse"}`}>
            <h1 className={`text-[#323E48] font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[44px] max-sm:text-[16px] ${currentLanguage==="ar" ?"ml-auto" : "mr-auto"}`}>
            {t("everySaturday")}
            </h1>
            <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`text-[#323E48] font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[44px] max-sm:text-[16px]  ${currentLanguage==="ar" ?"mr-auto" : "ml-auto"}`}>
            {data?.workingTime}
            </p>
        </div>
    )
}