import React from "react";
import left from "../../assets/button/left.svg";
import right from "../../assets/button/right.svg";

export default function Button({title, onClick, className}) {
    return(
        <button onClick={onClick} className={`relative font-amatic font-bold text-white 2k:text-[28px] fhd:text-[22px] hd:text-[14px] max-sm:tracking-[3px] bg-[#FB7F0D] rounded-[6px] p-[10px] ${className}`}>
            <img loading="lazy" alt="left" src={left} className="absolute right-1 top-1"/>
            <img loading="lazy" alt="right" src={right} className="absolute left-1 bottom-1"/>
            {title}
        </button>
    )
}