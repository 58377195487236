import React from "react";
import { useTranslation } from "react-i18next";

export default function InputField({className,readOnly,placeHolder,state,width,id,handleOnChange,dir,type,labelColor}) {
    
    const {  i18n } = useTranslation();

    const currentLanguage = i18n.language;
    
    return (
        <div className={`input-container fhd:mt-[3vh] hd:mt-[5vh] max-sm:mt-[1.4rem] ${width}`}>
            <input readOnly={readOnly} type={type} value={state} dir={dir} id={id} className={`${currentLanguage==="en"? "floating-input":"floating-input-ar"} font-amatic 2k:text-[30px] fhd:text-[24px] hd:text-[18px] max-sm:text-[12px] ${className}`} placeholder=" " onChange={handleOnChange}/>
            <label for={id} className={`${currentLanguage==="en"? "floating-label":"floating-label-ar"} ${labelColor} 2k:text-[30px] fhd:text-[24px] hd:text-[18px] max-sm:text-[12px] font-amatic ${type==="number" && currentLanguage==="ar" ? "mr-[28%]":""} ${type==="number" ? "ml-[42.5%] max-sm:ml-[34%]":""}`}>{placeHolder} </label>
        </div>
    )
}