import './Table.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Table() {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [searchQueryFirstName, setSearchQueryFirstName] = useState('');
  const [searchQueryLastName, setSearchQueryLastName] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubForm/getAll`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-UID': sessionStorage.getItem("uid")
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setFilteredData(data);
      });

  }, []);

  useEffect(() => {
    const filtered = data.filter(item =>
      item.childFirstName.toLowerCase().includes(searchQueryFirstName.toLowerCase()) &&
      item.childSecondName.toLowerCase().includes(searchQueryLastName.toLowerCase())
    );
    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchQueryFirstName, searchQueryLastName, data]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const navigate = useNavigate();

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRowClick = (id) => {
    navigate(`/admin/forms/${id}`);
  };

  return (
    <div className='m-auto '>
      <div className="bare">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      </div>
      <div>
        <nav className="navbar navbar-light bg-light">
          <div className="container-fluid">
            <div className="left">
              <h2>{ }</h2>
            </div>
            <div className={`${currentLanguage === "en" ? "right" : "left"}`} >
              <form className="d-flex">
                <div className="search-bar" dir={currentLanguage === "ar" ? "rtl" : "ltr"}>
                  <input
                    className="form-control me-4"
                    name="searchQueryFirstName"
                    type="search"
                    placeholder={t("searchByChildFirstName")}
                    aria-label="Search by Child's First Name"
                    value={searchQueryFirstName}
                    onChange={(e) => setSearchQueryFirstName(e.target.value)}
                  />
                  <input
                    className="form-control me-4"
                    name="searchQueryLastName"
                    type="search"
                    placeholder={t("searchByChildLastName")}
                    aria-label="Search by Child's Last Name"
                    value={searchQueryLastName}
                    onChange={(e) => setSearchQueryLastName(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </nav>
      </div>
      <table id="personalInfoTable" dir={currentLanguage === "ar" ? "rtl" : "ltr"}>
        <thead>
          <tr >
            <th className='whitespace-nowrap'>{t("guardianFirstName")}</th>
            <th className='whitespace-nowrap'>{t("guardianSecondName")}</th>
            <th className='whitespace-nowrap'>{t("phoneNumber")}</th>
            <th className='whitespace-nowrap'>{t("email")}</th>
            <th className='whitespace-nowrap'>{t("childFirstName")}</th>
            <th className='whitespace-nowrap'>{t("childSecondName")}</th>
            <th className='whitespace-nowrap'>{t("priceToPay")}</th>
            <th className='whitespace-nowrap'>{t("isPaid")}</th>
          </tr>
        </thead>
        <tbody id="tableBody">
          {currentItems.map((item, index) => (
            <tr key={index} onClick={() => handleRowClick(item.id)} style={{ cursor: 'pointer' }}>
              <td>{item.guardianFirstName}</td>
              <td>{item.guardianSecondName}</td>
              <td>{item.phoneNumber}</td>
              <td>{item.email}</td>
              <td>{item.childFirstName}</td>
              <td>{item.childSecondName}</td>
              <td>{item.feePrice}</td>
              <td className={`whitespace-nowrap ${item.paid ? "text-green-400" : "text-red-400"}`}>{!item.paid ? t("notPaid") : t("paid")}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button
          className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={prevPage}
          style={{ cursor: currentPage === 1 ? 'default' : 'pointer' }}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          className={`pagination-button ${currentPage >= Math.ceil(filteredData.length / itemsPerPage) ? 'disabled' : ''}`}
          onClick={nextPage}
          style={{ cursor: currentPage >= Math.ceil(filteredData.length / itemsPerPage) ? 'default' : 'pointer' }}
          disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}
        >
          Next
        </button>
      </div>
    </div>
  );
}
