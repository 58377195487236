import React, { useState, useEffect } from "react";
import character from "../../assets/character/1 (1).png"
import character2 from "../../assets/character/2.png"
import Stars from "./Stars";
import { BsBalloonHeartFill } from "react-icons/bs";
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidLeftArrow } from "react-icons/bi";
import { useTranslation } from "react-i18next";

export default function LoveNotes() {

    const { t } = useTranslation();

    const [notes, setNotes] = useState([]);

    const [currentNote, setCurrentNote] = useState(0);

    const nextNote = () => {
        setCurrentNote((currentNote + 1) % notes.length);
    }

    const prevNote = () => {
        setCurrentNote((currentNote - 1 + notes.length) % notes.length);
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customerNote/getTopCustomerNotes`)
        .then(response => response.json())
        .then(data => {
            setNotes(data);
        })
        .catch(error => console.error('Error:', error));
    }, []);

    useEffect(() => {
        if(notes.length>1) {
            const interval = setInterval(nextNote, 10000);
            return () => clearInterval(interval);
        }
    }, [currentNote]);
    return (
        <div className={`flex flex-col relative w-full max-sm:h-[22rem] fhd:h-[80vh] hd:h-[90vh] sm:mt-[10vh] max-sm:mt-[3rem] bg-[#57BEA4]`}>
            <img loading="lazy" alt="upla logo" src={character2} style={{ userSelect: "none" }} className="absolute bottom-14 right-16 max-sm:right-8 w-[16%]" />
            <img loading="lazy" alt="upla logo" src={character} style={{ transform: "rotateY(180deg)", userSelect: "none" }} className="absolute bottom-14 left-16 max-sm:left-8 w-[16%]" />
            <img loading="lazy" alt="upla logo" src={character} style={{ userSelect: "none" }} className="absolute top-14 right-16 max-sm:right-8 w-[16%]" />
            <img loading="lazy" alt="upla logo" src={character2} style={{ transform: "rotateY(180deg)", userSelect: "none" }} className="absolute top-14 left-16 max-sm:left-8 w-[16%]" />
            <BiSolidLeftArrow onClick={prevNote} className="cursor-pointer absolute text-white text-[36px] max-sm:text-[1.2rem] top-[45%] left-[15%]" />
            <BiSolidRightArrow onClick={nextNote} className="cursor-pointer absolute text-white text-[36px] max-sm:text-[1.2rem] top-[45%] right-[15%]" />
            <h1 className="font-amatic font-bold 2k:text-[70px] fhd:text-[64px] hd:text-[56px] max-sm:text-[1.4rem] text-white mr-auto ml-auto mt-[2%]">{t("noteTitle")}</h1>
            <Stars rating={notes[currentNote]?.customerRating} className={"mr-auto ml-auto"} />
            <p className="h-fit-content line-clamp-8 m-auto max-sm:flex w-[35%] max-sm:w-[50%]  max-sm:h-[10rem]  max-sm:mt-[3rem] text-center font-amatic text-white 2k:text-[42px] fhd:text-[36px] hd:text-[30px]">
                <span className="m-auto">{'"'}{notes[currentNote]?.customerText}{'"'}</span>
            </p>
            <div className="absolute bottom-[13%]  hd:w-[12rem] fhd:w-[13rem] 2k:w-[15rem] max-sm:w-[6rem] flex flex-col w-fit-content items-center max-sm:left-[36%] hd:left-[42%] fhd:left-[45%] 2k:left-[46%]">
                <BsBalloonHeartFill className="text-white 2k:text-[70px] fhd:text-[64px] hd:text-[56px] max-sm:mt-[1%] mt-[5%]" />
                <div className="font-amatic text-white hd:text-[46px] fhd:text-[40px] max-sm:text-[1rem] w-fit-content hd:text-[32px] whitespace-nowrap">
                    {notes[currentNote]?.customerName==="" ? t("anonymous") : notes[currentNote]?.customerName}
                </div>
            </div>
            <div className="w-full h-[220px]" />
        </div>
    )
}