import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
    signInWithPopup,
    onAuthStateChanged,
    signOut,
  } from "firebase/auth";



const firebaseConfig = {
    apiKey: "AIzaSyANqPPUeEvIrSPNKS7nv3zSHxSdMJwUcZ4",
    authDomain: "upla-789d5.firebaseapp.com",
    projectId: "upla-789d5",
    storageBucket: "upla-789d5.appspot.com",
    messagingSenderId: "686197130954",
    appId: "1:686197130954:web:e35c5d21447d986c639633",
    measurementId: "G-D3K74ZNLL8"
};

initializeApp(firebaseConfig);

export const auth = getAuth();

export const registerUser = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const loginUser = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};

const provider = new GoogleAuthProvider();

export const loginInWithGoogle = async () => signInWithPopup(auth, provider);

export const signOutUser = async () => await signOut(auth);
