import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {

  en: {
    translation: {
      "clubWelcome": "<strong>Welcome to Upla Club: Where Learning and Joy Meet!</strong>\n At Upla Club, we strive to offer an exceptional educational experience for children that combines fun with learning. We recognize the importance of the Arabic language as a vital part of our cultural identity and work to transform its learning into an exciting and enjoyable journey that nurtures a love for the language in children's hearts.\n <strong>Why Choose Upla Club?</strong>\n At Upla Club, we believe that Arabic is not just an academic subject; it is a core element of who we are, a tool for expression, and a bridge for communication.\n We work to remove the traditional barriers that may make the language seem dull, replacing them with creative activities that make children eager to attend every session. ",
      "language": "العربية",
      "titleClub": "Upla Club",
      "home": "Home",
      "upla": "Upla",
      "club": "Club",
      "expressYourFeling": "Express your feelings",
      "blog": "Blog",
      "allProducts": "All Products",
      "books": "Books",
      "gifts": "Gifts",
      "educationalGames": "Educational Games",
      "printables": "Printables",
      "learn": "ًWhere our language becomes fun and joy",
      "gallery": "Gallery",
      "title": "Where Fun and Joy Meet",
      "name": "Name ",
      "noteTitle": "Customers Love notes",
      "whatsUplaClub": "What is Upla Club",
      "firstName": "First Name",
      "lastName": "Last Name",
      "email": "Email",
      "phoneNumber": "Phone Number",
      "adress": "Adress",
      "city": "City",
      "age": "Age",
      "birthday": "Birthday",
      "schoolName": "School Name",
      "grade": "Grade",
      "tellUs": "Tell us about your child if there is something important about him or a medical condition",
      "agreementPhotos": "I agree for you to share my child's photos on all your social media",
      "agreementPolicy": "I agree to the privacy policy",
      "next": "Next",
      "submit": "Submit",
      "addNote": "Add Note",
      "noteAdded": "Note Added Successfully",
      "discoverMore": "Discover More",
      "guardian": "Guardian's Information",
      "child": "Child's Information",
      "regesrationForm": "Registration Form",
      "ageInterval": "Ages Between 6 to 12 Years Old",
      "registerNow": "Register Now",
      "timing": "Timing",
      "everySaturday": "Every Saturday",
      "time": "10:00 AM - 12:00 PM",
      "location": "Location",
      "school": "Elite School",
      "locationAddress": "No. 3, Al-Dakhla 4, 10160 Temara, Morocco",
      "price": "Prices (MAD)",
      "registrationFee": "Registration Fee :",
      "registrationFeeFree": " (Free Registration Fee)",
      "semesterFee": "Semester Fee ",
      "registration": "Registration",
      "oneSession": "One Session ",
      "registrationPrice": "250 MAD/year",
      "semesterPrice": "2150 Dh",
      "sessionPrice": "300 MAD",
      "specialOffer": "Special Offer",
      "specialOfferText": "Registration fee waived for quarterly registration",
      "OurTeachers": "Our Teachers",
      "dontMiss": "Don't miss the chance, places are limited",
      "happyToSee": "We are happy to see you there",
      "clubOp": "Learning Arabic is easy and exciting with Upla",
      "copyRight": "Created by bigoula tech group",
      "followUs": "Follow us in : ",
      "uplaInstagram": "Upla.Instagram",
      "getInTouch": "Get in touch",
      "policies": "Policies",
      "quickLinks": "Quick Links",
      "termsOfService": "Terms of Service",
      "privacyPolicy": "Privacy Policy",
      "aboutUs": "About us",
      "shippingPolicy": "Shipping Policy",
      "refundPolicy": "Refund Policy",
      "fullSemeseter": "Full Semester",
      "timeFrom": "Every Saturday from 10am to 12pm",
      "registrationFeeDescription": "For the Year",
      "sessionDescription": "To test the sessions in UPLA club",
      "unitPrice": "Unit Price :",
      "total": "Total :",
      "currency": "MAD",
      "workshop": "Our Excellent Workshop",
      "workshopArt": "Calligraphy Arts Workshop: Creative Writing",
      "workshopArtDescription": "In the <strong> Calligraphy Arts Workshop</strong>, we embark with children on an educational journey that starts with improving handwriting and ends with empowering them to write complete stories. We work on gradually developing their writing skills, starting from basic letters and words to writing texts that clearly and creatively express their ideas. This workshop not only improves handwriting, but also encourages children to express their ideas in an organized and clear way",
      "workshopReading": "Reading Skills Workshop: Fun Reading",
      "workshopReadingDescription": "In the <strong> Reading Skills Workshop</strong>, we offer an interactive reading experience that makes reading an exciting adventure. We focus on improving oral reading and comprehension through activities and stories suitable for their ages, enhancing their love of reading and developing their linguistic abilities",
      "workshopCommunication": "Expression and Communication Workshop: Creative Communication",
      "workshopCommunicationDescription": "The <strong> Expression and Communication Workshop</strong> aims to develop children's skills in expressing themselves in innovative ways. We provide them with the necessary tools to communicate confidently and effectively, whether through speaking in front of an audience or dialoguing with others. We strive for each child to be able to present their ideas creatively and confidently",
      "workshopLittleCreator": "Little Creator Workshop: Unlimited Innovation",
      "workshopLittleCreatorDescription": "In the <strong> Little Creator Workshop</strong>, we realize that each child has their own way of learning and expressing themselves. Whether the child loves art, writing, or science, we provide them with the tools and guidance they need to unleash their creativity and develop their skills in their chosen field. This workshop is designed to help children discover their talents, explore their interests, and express their ideas in a supportive and encouraging environment",
      "uplaFun": "Upla Club: Learning Arabic with Fun",
      "uplaFunDescription": 'At <strong> Upla Club</strong>, we are committed to providing a unique educational environment that makes learning Arabic an experience children look forward to every day. Our motto "Learning with Fun" is not just words, but the essence of everything we do, making us the ideal choice for developing your children\'s language skills in a fun and inspiring way',
      "joinUs": "Join us today!",
      "joinUsDescription": "Give your children the opportunity to learn Arabic in a fun and inspiring way. Register now to be part of the <strong> Upla Club</strong> family",
      "contactUs": "Contact us",
      "contactUsDescription": "Do you have any questions? We are here to help! Contact us via",
      "email1": "Email :",
      "phone1": "Phone :",
      "location1": "Location :",
      "website": "You can also visit our website or our social media pages for more information and updates on our upcoming events and activities",
      "About our club": "About our club",
      "expressUpla": "Express Your Feelings about Upla",
      "youCanShare": "You can share my child photos",
      "youCantShare": "You can't share my child photos",
      "ntgToShow": "Nothing to show",
      "pay": "Pay",
      "paymentFee": "Payment Fee",
      "regisrtationYear": "Registration for the year",
      "registrationType": "Registration Type",
      "priceToPay": "Price to Pay",
      "isPaid": "Is Paid?",
      "paid": "Paid",
      "notPaid": "Not Paid",
      "childFirstName": "Child First Name",
      "childSecondName": "Child Last Name",
      "guardianFirstName": "Guardian First Name",
      "guardianSecondName": "Guardian Last Name",
      "searchByChildFirstName": "Search by Child's First Name",
      "searchByChildLastName": "Search by Child's Last Name",
      "why.ourTeam": "Why Our Team?",
      "answer.team": "Our dedicated team at Upla is committed to making Arabic learning an exciting and enriching experience. Each teacher brings a unique set of skills and a passion for education that helps foster a love for the Arabic language in every child.",
      "chaimaeBigoula": "Chaimae Bigoula | Arabic Instructor",
      "chaimaeBigoula.role": "<strong>Role:</strong> Arabic Instructor",
      "chaimaeBigoula.qualifications": "<strong>Qualifications: </strong> Certified teacher with a Bachelor of Arts and nine years of experience in education and a certification in Child Protection.",
      "chaimaeBigoula.specialization": "<strong>Specialization: </strong> Expert in modern Arabic instruction, blending creativity with tradition to bring the language to life for young learners.",
      "chaimaeBigoula.philosophy": "<strong>Teaching Philosophy: </strong> With a background in English, I’ve embraced a unique approach to teaching Arabic, one that breaks away from the ordinary. My mission is to make Arabic not just a subject, but a passion for every child. I believe in the power of creativity, engagement, and joy in the classroom, ensuring that every student feels connected to the language in a meaningful way.",
      "chaimaeBigoula.experience": "<strong>Experience: </strong> As an Arabic teacher at Rabat American School, I have the privilege of empowering PreK-5th graders—both native and non-native speakers—by creating a learning environment where they can thrive. My nine years in education have taught me that when students enjoy the process, their love for the language blossoms naturally.",
      "majida": "Majida Nasri | Arabic Instructor",
      "majida.role": "<strong>Role:</strong> Arabic Instructor",
      "majida.qualifications": "<strong>Qualifications: </strong> She holds a BA in Arabic Studies and several trainings in Arabic language teaching, with 10 years of teaching experience",
      "majida.specialization": "<strong>Specialization: </strong> Specializing in teaching Arabic to children in a modern way based on interactive activities and the development of language skills.",
      "majida.philosophy": "<strong>Teaching Philosophy: </strong> I believe that effective education is one that mixes Fun and benefit. I seek to provide an educational environment that encourages children to love the Arabic language by integrating games and technology into the classroom.",
      "majida.experience": "<strong>Experience: </strong> I have experience teaching Arabic to different ages, from kindergarten to primary school, with a focus on developing reading and writing skills in innovative ways.",
      "previous": "Previous",
      "anonymous": "Anonymous",
      "openRegistration": "Open Registration in ",
      "popupTitle": "Book Your Trial Session With Us",
      "trialSession": "Trial Session",
      "offer.Semester": "Trimester now just for 2100 MAD instead of 2850 MAD",
      "offer.Trial": "150 MAD for Trial Session",
      "freeTrial.price" : "150 MAD",
      "login" : "Login",
      "approved" : "Approved",
      "notApproved" : "Not Approved",
      "note" : "Note",
      "rating" : "Rating",
      "quranWorkshop.title" : "The Light of the Quran: Light and Elevation",
      "quranWorkshop.description": "In \"<strong>The Light of the Quran</strong>,\" we aim to nurture a love for the Holy Quran in the hearts of children and teach them its noble values and profound meanings. Our simple yet engaging approach strengthens their connection to the Book of Allah.<br/><strong>Workshop Topics:</strong><ul className='list-disc list-inside flex flex-col w-fit-content mx-auto mt-[2vh]'><li><strong>Recitation of the Holy Quran:</strong> Helping children memorize and develop fluent, correct recitation skills.</li><li><strong>Rules of Tajweed:</strong> Simplifying Tajweed rules with practical applications to perfect their recitation.</li><li><strong>Stories from the Quran:</strong> Presenting inspiring stories from the Quran and discussing the lessons and values they convey.</li><li><strong>Adhkar and the Names of Allah:</strong> Teaching Adhkar (remembrances) and Allah’s Names through interactive activities that encourage children to incorporate them into their daily routines.</li><li><strong>Foundations of Faith:</strong> Simplifying concepts of Islamic creed in ways that align with children’s understanding, deepening their knowledge of the fundamentals of faith.</li></ul>",
      "pagenotfound": "Page Not Found",
      "discoverClub" : "Discover more about our club",
    }
  },
  ar: {
    translation: {
      "clubWelcome": "<strong>مرحبًا بكم في نادي أوپلا: حيث تلتقي المعرفة والفرح!</strong>\n في نادي أوپلا، نسعى لتقديم تجربة تعليمية استثنائية للأطفال تجمع بين المرح والتعلم. نحن ندرك أهمية اللغة العربية كجزء حيوي من هويتنا الثقافية ونعمل على تحويل تعلمها إلى رحلة مثيرة وممتعة تغرس حب اللغة في قلوب الأطفال.\n <strong>لماذا تختار نادي أوپلا؟</strong>\n في نادي أوپلا، نؤمن بأن العربية ليست مجرد مادة أكاديمية؛ بل هي عنصر أساسي من هويتنا، وأداة للتعبير، وجسر للتواصل.\n نحن نعمل على إزالة الحواجز التقليدية التي قد تجعل اللغة تبدو مملة، ونستبدلها بأنشطة إبداعية تجعل الأطفال متحمسين للحضور في كل جلسة.",
      "language": "English",
      "titleClub": "نادي أوپلا",
      "home": "الرئيسية",
      "club": "النادي",
      "expressYourFeling": "عبر عن مشاعرك",
      "blog": "المدونة",
      "allProducts": "كل المنتجات",
      "books": "الكتب",
      "gifts": "الهدايا",
      "educationalGames": "الألعاب التعليمية",
      "printables": "المطبوعات",
      "learn": "حيث تصبح لغتنا متعة وفرح",
      "gallery": "المعرض",
      "title": "حيث يلتقي المرح والفرح",
      "name": "الاسم ",
      "noteTitle": "رسائل تسعد القلب",
      "whatsUplaClub": "ما هو نادي أوپلا",
      "firstName": "الاسم الشخصي",
      "lastName": "الاسم العائلي",
      "email": "البريد الإلكتروني",
      "phoneNumber": "رقم الهاتف",
      "adress": "العنوان",
      "city": "المدينة",
      "age": "العمر",
      "birthday": "تاريخ الميلاد",
      "schoolName": "اسم المدرسة",
      "grade": "الصف",
      "tellUs": "أخبرنا عن طفلك إذا كان هناك شيء مهم بشأنه أو حالة طبية",
      "agreementPhotos": "أوافق على مشاركة صور طفلي على جميع وسائل التواصل الاجتماعي الخاصة بك",
      "agreementPolicy": "أوافق على سياسة الخصوصية",
      "next": "التالي",
      "submit": "إرسال",
      "addNote": "إضافة ملاحظة",
      "noteAdded": "تمت إضافة الملاحظة بنجاح",
      "discoverMore": "اكتشف المزيد",
      "guardian": "معلومات ولي الأمر",
      "child": "معلومات الطفل",
      "regesrationForm": " استمارة التسجيل",
      "ageInterval": "الأعمار بين 6 و 12 سنة",
      "registerNow": "سجل الآن",
      "timing": "التوقيت",
      "everySaturday": "كل يوم سبت",
      "time": "10:00 صباحًا - 12:00 ظهرًا",
      "location": "الموقع",
      "school": "مدرسة إيليت",
      "locationAddress": "رقم 3، الداخلة 4، 10160 تمارة، المغرب",
      "price": " الاسعار (درهم)",
      "registrationFee": "رسوم التسجيل",
      "registrationFeeFree": "(رسوم التسجيل مجانية)",
      "semesterFee": "رسوم الفصل",
      "registration": "التسجيل",
      "oneSession": "جلسة واحدة",
      "registrationPrice": "250 درهم / سنة",
      "semesterPrice": "درهم 2150",
      "sessionPrice": "300 درهم",
      "specialOffer": "عرض خاص",
      "specialOfferText": "إعفاء رسوم التسجيل للتسجيل الفصلي",
      "OurTeachers": "معلمونا",
      "dontMiss": "لا تفوت الفرصة، الأماكن محدودة",
      "happyToSee": "نحن سعداء برؤيتك هنا",
      "upla": "أوپلا",
      "clubOp": "تعلم اللغة العربية سهل ومثير مع أوپلا",
      "copyRight": "تم إنشاء الموقع بواسطة مجموعة بيغولا تيك",
      "followUs": " : تابعنا على  ",
      "uplaInstagram": "أوپلا.انستغرام",
      "getInTouch": "تواصل معنا",
      "policies": "السياسات",
      "quickLinks": "روابط سريعة",
      "termsOfService": "شروط الخدمة",
      "privacyPolicy": "سياسة الخصوصية",
      "aboutUs": "من نحن",
      "shippingPolicy": "سياسة الشحن",
      "refundPolicy": "سياسة الاسترجاع",
      "fullSemeseter": "الفصل الكامل",
      "timeFrom": "كل يوم سبت من الساعة 10 صباحًا حتى 12 ظهرًا",
      "registrationFeeDescription": "للسنة",
      "sessionDescription": "لتجربة الجلسات في نادي أوبلا",
      "unitPrice": " السعر الوحدوي :",
      "total": "المجموع :",
      "currency": "درهم",
      "workshop": "ورشة عملنا الممتازة",
      "workshopArt": "ورشة فنون الخط: كتابة بإبداع",
      "workshopArtDescription": "في <strong>ورشة فنون الخط</strong>، ننطلق مع الأطفال في رحلة تعليمية تبدأ من تحسين الكتابة اليدوية وتنتهي بتمكينهم من كتابة قصص كاملة. نعمل على تطوير مهاراتهم الكتابية بشكل تدريجي، بدءًا من الحروف والكلمات الأساسية وصولاً إلى كتابة نصوص تعبر عن أفكارهم بوضوح وإبداع. هذه الورشة لا تُحسن من الخط فحسب، بل تشجع الأطفال على التعبير عن أفكارهم بطريقة منظمة وواضحة",
      "workshopReading": "ورشة مهارات القراءة: قراءة ممتعة",
      "workshopReadingDescription": "في <strong> ورشة مهارات القراءة</strong>، نقدم تجربة قراءة تفاعلية تجعل من القراءة مغامرة مشوقة. نركز على تحسين القراءة الجهرية والفهم من خلال أنشطة وقصص ملائمة لأعمارهم، مما يعزز حبهم للقراءة وينمي قدراتهم اللغوية",
      "workshopCommunication": "ورشة التعبير والتواصل: تواصل بإبداع",
      "workshopCommunicationDescription": ".تهدف <strong> ورشة التعبير والتواصل</strong> إلى تطوير مهارات الأطفال في التعبير عن أنفسهم بطرق مبتكرة. نقدم لهم الأدوات اللازمة للتواصل بثقة وفعالية، سواء من خلال التحدث أمام الجمهور أو الحوار مع الآخرين. نسعى لأن يكون كل طفل قادرًا على تقديم أفكاره بإبداع وثقة",
      "workshopLittleCreator": "ورشة الإبداع الصغير: ابتكار بلا حدود",
      "workshopLittleCreatorDescription": "في <strong> ورشة المبدع الصغير</strong>، ندرك أن لكل طفل طريقته الخاصة في التعلم والتعبير عن نفسه. سواء كان الطفل يحب حفظ الأغاني، أو تمثيل القصص، أو رسم ما يتعلمه، فإن هذه الورشة مصممة لمساعدتهم على اكتشاف شرارتهم الإبداعية. نحن نستخدم مجموعة متنوعة من الأنشطة الفنية والابتكارية لتعليم المهارات الأساسية، مما يسمح لكل طفل بالتعلم بالطريقة التي تناسبه. من خلال خلق بيئة تجمع بين الإبداع والتعلم، نساعد الأطفال على اكتشاف ما يستمتعون به حقًا، ونفتح أمامهم آفاقًا جديدة في جو داعم وملهم",
      "uplaFun": "نادي أوپلا: لنتعلم بمرح في تعلم اللغة العربية",
      "uplaFunDescription": 'في <strong> نادي أوپلا</strong>، نحن ملتزمون بتقديم بيئة تعليمية فريدة تجعل تعلم اللغة العربية تجربة يتطلع إليها الأطفال كل يوم. شعارنا "لنتعلم بمرح" ليس مجرد كلمات، بل هو جوهر كل ما نقوم به، مما يجعلنا الخيار الأمثل لتنمية مهارات أطفالكم اللغوية بطريقة ممتعة ومُلهمة',
      "joinUs": "!انضموا إلينا اليوم",
      "joinUsDescription": " !امنحوا أطفالكم الفرصة لتعلم اللغة العربية بطريقة ممتعة ومُلهمة. سجلوا الآن لتكونوا جزءًا من عائلة <strong> نادي أوپلا</strong>",
      "contactUs": ": للتواصل معنا ",
      "contactUsDescription": ": هل لديكم أي استفسارات؟ نحن هنا للمساعدة! تواصلوا معنا عبر",
      "email1": " البريد الإلكتروني",
      "phone1": " الهاتف",
      "location1": " الموقع",
      "website": " .يمكنكم أيضًا زيارة موقعنا الإلكتروني أو صفحاتنا على وسائل التواصل الاجتماعي لمزيد من المعلومات والتحديثات حول فعالياتنا وأنشطتنا القادمة",
      "About our club": "عن نادينا",
      "expressUpla": "عبر عن مشاعرك حول أوپلا",
      "youCanShare": "يمكنك مشاركة صور طفلي",
      "youCantShare": "لا يمكنك مشاركة صور طفلي",
      "ntgToShow": "لا يوجد شيء لعرضه",
      "pay": "ادفع",
      "paymentFee": "رسوم الدفع",
      "regisrtationYear": "التسجيل للسنة",
      "registrationType": "نوع التسجيل",
      "priceToPay": "السعر المطلوب",
      "isPaid": "هل تم الدفع؟",
      "paid": "تم الدفع",
      "notPaid": "لم يتم الدفع",
      "childFirstName": "الاسم الشخصي للطفل",
      "childSecondName": "الاسم العائلي للطفل",
      "guardianFirstName": "الاسم الشخصي لولي الأمر",
      "guardianSecondName": "الاسم العائلي لولي الأمر",
      "searchByChildFirstName": "البحث بالاسم الشخصي للطفل",
      "searchByChildLastName": "البحث بالاسم العائلي للطفل",
      "why.ourTeam": "لماذا فريقنا؟",
      "answer.team": "فريقنا في نادي أوپلا ملتزم بتقديم تجربة تعليمية مثيرة ومثرية في تعلم اللغة العربية. يساهم كل عضو في الفريق بمهارات فريدة وشغف عميق بالتعليم، مما يساعد على تعزيز حب اللغة العربية لدى كل طفل",
      "chaimaeBigoula": "شيماء بيكولا | مدرسة اللغة العربية",
      "chaimaeBigoula.role": "<strong>الدور:</strong> مدرسة اللغة العربية",
      "chaimaeBigoula.qualifications": "<strong>المؤهلات: </strong> معلمة معتمدة بدرجة البكالوريوس وتسع سنوات من الخبرة في التعليم وشهادة في حماية الطفل",
      "chaimaeBigoula.specialization": "<strong>التخصص: </strong> خبيرة في تدريس اللغة العربية الحديثة، مزج الإبداع بالتقاليد لإحياء اللغة لدى الأطفال الصغار",
      "chaimaeBigoula.philosophy": "<strong>فلسفة التدريس: </strong> بفضل خلفيتي في اللغة الإنجليزية، اعتمدت نهجًا فريدًا في تدريس اللغة العربية، يخرج عن المألوف. مهمتي هي جعل اللغة العربية ليست مجرد مادة دراسية، بل شغف لكل طفل. أؤمن بقوة الإبداع والمشاركة والفرح في الصف، مما يضمن أن يشعر كل طالب بالتواصل مع اللغة بطريقة معنوية",
      "chaimaeBigoula.experience": "<strong>الخبرة: </strong> كمدرسة لغة عربية في مدرسة رباط الأمريكية، لدي الامتياز في تمكين طلاب الروضة والصفوف الأولى إلى الخامسة - سواء الناطقين بالعربية أو غير الناطقين بها - من خلق بيئة تعليمية يمكنهم الازدهار فيها. علمتني تسع سنوات في التعليم أنه عندما يستمتع الطلاب بالعملية، يزدهرون بحبهم للغة",
      "majida": " مجيدة ناصري | مدرسة اللغة العربية",
      "majida.role": "<strong>الدور:</strong>  مدرسة اللغة العربية",
      "majida.qualifications": "<strong>المؤهلات: </strong> حاصلة على شهادة الإجازة في الدراسات العربية وعدة تدريبات في تدريس  اللغة العربية، مع خبرة 10 سنوات في التدريس",
      "majida.specialization": "<strong>التخصص: </strong> متخصصة في تعليم اللغة العربية للأطفال بطريقة حديثة تعتمد على الأنشطة التفاعلية وتنمية المهارات اللغوية",
      "majida.philosophy": "<strong>فلسفة التدريس: </strong> أؤمن بأن التعليم الفعّال هو الذي يمزج بين المتعة والفائدة. أسعى لتوفير بيئة تعليمية تشجع الأطفال على حب اللغة العربية من خلال دمج الألعاب والتكنولوجيا في الحصص الدراسية",
      "majida.experience": "<strong>الخبرة: </strong>  لدي خبرة في تدريس اللغة العربية لمختلف المراحل العمرية، من مرحلة الروض إلى مرحلة الابتدائي، مع التركيز على تطوير مهارات القراءة والكتابة بأساليب مبتكرة",
      "previous": "السابق",
      "anonymous": "مجهول",
      "openRegistration": "التسجيل مفتوح في ",
      "popupTitle": "احجز جلستك التجريبية معنا",
      "trialSession": "جلسة تجريبية",
      "offer.Semester": " الربع الان فقط بـ 2100 درهم بدلاً من 2850 درهم"  ,
      "offer.Trial": "150 درهم لجلسة التجريبية",
      "freeTrial.price" : "150 درهم",
      "login" : "تسجيل الدخول",
      "approved" : "مقبول",
      "notApproved" : "غير مقبول",
      "note" : "الرسالة",
      "rating" : "التقييم",
      "quranWorkshop.title" : "نور القرآن: نور وارتقاء",
      "quranWorkshop.description": "في \"<strong>نور القرآن</strong>,\" نهدف إلى تنمية حب القرآن الكريم في قلوب الأطفال وتعليمهم قيمه النبيلة ومعانيه العميقة. يعزز نهجنا البسيط والمشوق ارتباطهم بكتاب الله.\n<strong>مواضيع الورشة:</strong>\n<ul className=\"list-disc list-inside flex flex-col w-fit-content mx-auto mt-[2vh]\"><li><strong>تلاوة القرآن الكريم:</strong> مساعدة الأطفال على حفظه وتطوير مهارات التلاوة السليمة والسلسة.</li><li><strong>قواعد التجويد:</strong> تبسيط قواعد التجويد مع تطبيقات عملية لتحسين تلاوتهم.</li><li><strong>قصص من القرآن الكريم:</strong> تقديم قصص ملهمة من القرآن الكريم ومناقشة الدروس والقيم التي تنقلها.</li><li><strong>الأذكار وأسماء الله:</strong> تعليم الأذكار وأسماء الله من خلال أنشطة تفاعلية تشجع الأطفال على تضمينها في روتينهم اليومي.</li><li><strong>أسس العقيدة:</strong> تبسيط مفاهيم العقيدة الإسلامية بطرق تتماشى مع فهم الأطفال، معززين معرفتهم بالمبادئ الأساسية للإيمان.</li></ul>",
      "pagenotfound" : "الصفحة غير موجودة",
      "discoverClub" : "اكتشف المزيد عن نادينا",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ar',
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;