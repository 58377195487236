
import { Trans, useTranslation } from "react-i18next";



export default function Workshop({ src, title, dangerouslySetInnerHTML, description }) {

    const { i18n } = useTranslation();

    const currentLanguage = i18n.language;


    return (
        <div className={`flex flex-row ${currentLanguage === "ar" && "flex-row-reverse"} w-[86%] ml-auto mr-auto mt-[6vh] gap-x-20`}>
            <div className="flex flex-col mt-[0.5%]">
                <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={` mx-auto ${currentLanguage === "ar" ? "font-nokta" : "font-amatic"} ${currentLanguage === "ar" && "ml-auto"} font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] text-[#323E48] w-fit-content cursor-default hover:text-[#FB7F0D]`}>{title}</h1>
                <img loading="lazy" alt="arabic club workshop" src={src} className="mx-auto w-[80%] mt-[4%]" />
                <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} dangerouslySetInnerHTML={dangerouslySetInnerHTML} className="font-amatic text-justify mx-auto w-[80%] mt-[4%] fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] whitespace-pre-line"/>
                <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify mx-auto w-[80%] mt-[4%] fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] whitespace-pre-line"><Trans i18nKey={description} /></p>
            </div>
        </div>
    )
}
