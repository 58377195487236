import React, { useEffect, useState } from "react";
import SpecialOffers from "../utils/components/SpecialOffers";
import NavigationBar from "../utils/components/navigation/NavigationBar";
import TopImage from "../utils/components/TopImage";
import background from "../assets/club/topImage.svg"
import Footer from "../utils/components/footer/Footer";
import CopyRight from "../utils/components/footer/CopyRight";
import InputField from "../utils/components/club/InputField";
import image from "../assets/club/DSC_8772.jpg"
import TextArea from "../utils/components/club/TextArea";
import Button from "../utils/components/Button";
import { useNavigate } from "react-router-dom";
import useFormContext from "../contexts/form/useFormContext";
import { useTranslation } from "react-i18next";

export default function ClubFormPage() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const [data, setData] = useState()


    const navigate = useNavigate()

    const [form, setForm] = useState({
        guardianFirstName: "",
        guardianSecondName: "",
        email: "",
        adress: "",
        phoneNumber: "",
        city: "",
        childFirstName: "",
        childSecondName: "",
        age: 6,
        birthDay: "",
        schoolName: "",
        grade: "",
        aboutChild: "",
        socialMediaConsent: false
    });

    const { setCurrentForm } = useFormContext();

    const [guardianFirstName, setGuardianFirstName] = useState("")
    const [guardianSecondName, setGuardianSecondName] = useState("")
    const [email, setEmail] = useState("")
    const [adress, setAdress] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [city, setCity] = useState("")

    const [childFirstName, setChildFirstName] = useState("")
    const [childSecondName, setChildSecondName] = useState("")
    const [age, setAge] = useState(6)
    const [birthDay, setBirthDay] = useState("")
    const [schoolName, setSchoolName] = useState("")
    const [grade, setGrade] = useState("")
    const [aboutChild, setAboutChild] = useState("")

    const [socialMediaConsent, setSocialMediaConsent] = useState(false);
    const [policyAgreement, setPolicyAgreement] = useState(false);

    const [shake, setShake] = useState(false);
    const [errors, setErrors] = useState({});


    const handleOnChange = (event) => {
        const { id, checked } = event.target;

        const newErrors = { ...errors, [id]: false };

        switch (event.target.id) {
            case 'guardianFirstName':
                setGuardianFirstName(event.target.value);
                setForm({...form, guardianFirstName: event.target.value})
                break;
            case 'guardianSecondName':
                setGuardianSecondName(event.target.value);
                setForm({...form, guardianSecondName: event.target.value})
                break;
            case 'email':
                setEmail(event.target.value);
                setForm({...form, email: event.target.value})
                break;
            case 'adress':
                setAdress(event.target.value);
                setForm({...form, adress: event.target.value})
                break;
            case 'phoneNumber':
                setPhoneNumber(event.target.value);
                setForm({...form, phoneNumber: event.target.value})
                break;
            case 'city':
                setCity(event.target.value);
                setForm({...form, city: event.target.value})
                break;
            case 'childFirstName':
                setChildFirstName(event.target.value);
                setForm({...form, childFirstName: event.target.value})
                break;
            case 'childSecondName':
                setChildSecondName(event.target.value);
                setForm({...form, childSecondName: event.target.value})
                break;
            case 'age':
                setAge(event.target.value);
                setForm({...form, age: event.target.value})
                break;
            case 'birthDay':
                setBirthDay(event.target.value);
                setForm({...form, birthDay: event.target.value})
                break;
            case 'schoolName':
                setSchoolName(event.target.value);
                setForm({...form, schoolName: event.target.value})
                break;
            case 'grade':
                setGrade(event.target.value);
                setForm({...form, grade: event.target.value})
                break;
            case 'aboutChild':
                setAboutChild(event.target.value);
                setForm({...form, aboutChild: event.target.value})
                break;
            case 'social-media':
                setSocialMediaConsent(checked);
                setForm({...form, socialMediaConsent: event.target.value === "on" ? true : false });                break;
            case 'policy':
                setPolicyAgreement(checked);
                break;
            default:
                break;
        }
        setErrors(newErrors);
    };

    const handleSubmit = () => {
        const newErrors = {};

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            newErrors.email = true;
        }

        if (!birthDay) {
            newErrors.birthDay = true;
        } else {
            const today = new Date();
            const birthDate = new Date(birthDay);
            const calculatedAge = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();

            if (
                calculatedAge !== parseInt(age) ||
                (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate()))
            ) {
                newErrors.age = true;
                newErrors.birthDay = true;
            }
        }

        if (!guardianFirstName) newErrors.guardianFirstName = true;
        if (!guardianSecondName) newErrors.guardianSecondName = true;
        if (!adress) newErrors.adress = true;
        if (!phoneNumber) newErrors.phoneNumber = true;
        if (!city) newErrors.city = true;
        if (!childFirstName) newErrors.childFirstName = true;
        if (!childSecondName) newErrors.childSecondName = true;
        if (!schoolName) newErrors.schoolName = true;
        if (!grade) newErrors.grade = true;
        if (!policyAgreement) newErrors.policy = true;

        setErrors(newErrors);

        const isFormValid = Object.keys(newErrors).length === 0;
        if (isFormValid) {
            setCurrentForm(form)
            navigate("/club/form/paiment")
        } else {
            setShake(true);
            setTimeout(() => setShake(false), 500);
        }
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubInformation/get/${currentLanguage}`)
            .then(response => response.json())
            .then(data => {
                setData(data);
            })

    }, [localStorage.getItem("language")]);
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="w-full h-auto">
            <SpecialOffers />
            <NavigationBar />
            <TopImage>
                <img loading="lazy" alt="club form" src={background} className="absolute object-cover sm:h-[75vh] max-sm:h-[15rem] w-full" />
                <div className={`fhd:w-[34rem] hd:w-[26rem] text-center fhd:pt-[8rem] hd:pt-[5rem] max-sm:mt-[2.5rem] max-sm:h-[10rem] max-sm:w-[10rem] max-sm:pt-[2rem] pl-[2rem] pr-[2rem] fhd:h-[34rem] hd:h-[26rem] bg-[#FFE358]/50 z-[2] ${currentLanguage === "ar" && "flex-row-reverse"} m-auto rounded-full`}>
                <h1 className="font-amatic font-bold text-stroke text-stroke-gray text-[#57BEA4] sm:text-[64px]  mt-auto ml-auto mr-auto ">{t("upla")} <span className="text-[#FB7F0D]">{t("club")}</span></h1>
                    <h1 className="font-amatic font-bold text-stroke text-stroke-gray text-white sm:text-[40px]  ml-auto mr-auto mb-auto">{t("clubOp")}</h1>
                </div>
            </TopImage>
            <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`flex flex-col w-[50%] max-sm:w-[80%] mx-auto`}>
                <h1 className="font-amatic font-bold fhd:text-[64px] hd:text-[52px] max-sm:text-[16px] text-[#323E48] mx-auto  mt-[6vh]">{t("regesrationForm")} </h1>
                {data && <h1 className="font-amatic font-bold fhd:text-[40px] hd:text-[34px] text-[#323E48] mx-auto  mt-[6vh]">{data.semesterTitle} {data.semesterDuration}</h1>}
                <img loading="lazy" alt="arabic club" src={image} className="w-full mt-[6vh] h-[80vh] max-sm:h-[20rem] rounded-[10px]" />
                <h1 className="font-amatic font-bold fhd:text-[40px] hd:text-[34px] text-[#323E48]  mt-[6vh]">{t("guardian")}</h1>
                <div className="flex flex-row gap-x-[20px]">
                    <InputField
                        className={`rounded-[15px] bg-white focus:bg-white ${errors.guardianFirstName ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                        type="text" width={"w-[50%]"}
                        state={guardianFirstName}
                        placeHolder={t("firstName")}
                        id="guardianFirstName"
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        handleOnChange={handleOnChange}
                        labelColor={`${errors.guardianFirstName ? 'text-red-700 ' : 'text-black'}`}
                    />
                    <InputField
                        className={`rounded-[15px] bg-white focus:bg-white ${errors.guardianSecondName ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                        type="text" width={"w-[50%]"}
                        state={guardianSecondName}
                        placeHolder={t("lastName")}
                        id="guardianSecondName"
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        handleOnChange={handleOnChange}
                        labelColor={`${errors.guardianSecondName ? 'text-red-700 ' : 'text-black'}`}
                    />
                </div>
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.email ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    type="text"
                    width={"w-[50%]"}
                    state={email}
                    placeHolder={t("email")}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    id="email"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.email ? 'text-red-700 ' : 'text-black'}`}

                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.phoneNumber ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    type="text"
                    width={"w-[50%]"}
                    state={phoneNumber}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("phoneNumber")}
                    id="phoneNumber"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.phoneNumber ? 'text-red-700 ' : 'text-black'}`}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.adress ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    type="text"
                    width={"w-[100%]"}
                    state={adress}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("adress")}
                    id="adress"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.adress ? 'text-red-700 ' : 'text-black'}`}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.city ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    type="text"
                    width={"w-[100%]"}
                    state={city}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("city")}
                    id="city"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.city ? 'text-red-700 ' : 'text-black'}`}
                />
                <h1 className="font-amatic font-bold fhd:text-[40px] hd:text-[34px] text-[#323E48]  mt-[6vh]">{t("child")}</h1>
                <div className="flex flex-row gap-x-[30px]">
                    <InputField
                        className={`rounded-[15px] bg-white focus:bg-white ${errors.childFirstName ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                        type="text"
                        width={"w-[50%]"}
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        state={childFirstName}
                        placeHolder={t("firstName")}
                        id="childFirstName"
                        handleOnChange={handleOnChange}
                        labelColor={`${errors.childFirstName ? 'text-red-700 ' : 'text-black'}`}
                    />
                    <InputField
                        className={`rounded-[15px] bg-white focus:bg-white ${errors.childSecondName ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                        type="text"
                        width={"w-[50%]"}
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        state={childSecondName}
                        placeHolder={t("lastName")}
                        id="childSecondName"
                        handleOnChange={handleOnChange}
                        labelColor={`${errors.childSecondName ? 'text-red-700 ' : 'text-black'}`}
                    />
                </div>
                <InputField
                    className={`rounded-[15px] ${errors.age ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black '} text-center`}
                    type="number"
                    width={"w-[50%]"}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    state={age}
                    placeHolder={t("age")}
                    id="age"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.age ? 'text-red-700 ' : 'text-black'}`}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.birthDay ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    width={"w-[50%]"}
                    type="date"
                    state={birthDay}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("birthday")}
                    id="birthDay"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.birthDay ? 'text-red-700 ' : 'text-black'}`}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.schoolName ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    width={"w-[50%]"}
                    type="text"
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    state={schoolName}
                    placeHolder={t("schoolName")}
                    id="schoolName"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.schoolName ? 'text-red-700 ' : 'text-black'}`}
                />
                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.grade ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    width={"w-[50%]"}
                    type="text"
                    state={grade}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("grade")}
                    id="grade"
                    handleOnChange={handleOnChange}
                    labelColor={`${errors.grade ? 'text-red-700 ' : 'text-black'}`}
                />
                <div>
                    <input
                        type="checkbox"
                        id="social-media"
                        className="mt-[6vh] max-sm:mt-[3vh] size-[24px] max-sm:size-[16px] accent-[#58C2A6]"
                        checked={socialMediaConsent}
                        onChange={handleOnChange}
                    />
                    <label dir={currentLanguage === "ar" ? "rtl" : "ltr"} htmlFor="social-media" className={`font-amatic ${currentLanguage==="en" ? "ml-[2%]" : "mr-[2%]"} fhd:text-[40px] select-none hd:text-[34px] text-[#323E48]`}>
                        {t("agreementPhotos")}
                    </label>
                </div>
                <TextArea
                    className={`rounded-[15px] bg-white focus:bg-white ${errors.aboutChild ? 'border-[1px] border-red-700 ' : 'border-[1px] border-black'}`}
                    width={"w-[100%]"}
                    type="text"
                    state={aboutChild}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("tellUs")}
                    id="aboutChild"
                    handleOnChange={handleOnChange}
                />
                <div>
                    <input
                        type="checkbox"
                        id="policy"
                        dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                        className={`mt-[10vh] max-sm:mt-[3vh] size-[24px] max-sm:size-[16px] accent-[#58C2A6]`}
                        checked={policyAgreement}
                        onChange={handleOnChange}
                    />
                    <label dir={currentLanguage === "ar" ? "rtl" : "ltr"}  htmlFor="policy" className={`font-amatic ${currentLanguage==="en" ? "ml-[2%]" : "mr-[2%]"} fhd:text-[40px] ${errors.policy ? 'text-red-700 ' : 'text-black'} select-none hd:text-[34px] text-[#323E48]`}>
                        {t("agreementPolicy")} <span className="text-red-600">*</span>
                    </label>
                </div>
                <Button
                    title={t("next")}
                    className={`z-[2] ml-auto mr-auto mt-[5vh] w-[10vw] max-sm:w-[6rem] mas-sm:text-[10px] rounded-full tracking-[10px] max-sm:tracking-[4px] ${shake ? 'animate-shake' : ''
                        }`}
                    onClick={handleSubmit}
                />
            </div>
            <Footer />
            <CopyRight />
        </div>
    )
}