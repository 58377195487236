import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import image from "../assets/gallery/DSC_8844.jpg"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Popup = () => {

    const { t } = useTranslation();

    // const currentLanguage = i18n.language;

    const [showPopup, setShowPopup] = useState(false);

    // const [data, setData] = useState([]);

    const navigate = useNavigate();

    const closePopup = () => {
        setShowPopup(false);
        sessionStorage.setItem('popupShown', 'true');
    };

    // useEffect(() => {
    //     fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubInformation/get/${localStorage.getItem("language")? localStorage.getItem("language"): "en"}`)
    //     .then(response => response.json())
    //     .then(data => {
    //         setData(data);
    //     })

    // }, [localStorage.getItem("language")]);



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const popupShown = sessionStorage.getItem('popupShown');
            if (!popupShown) {
                setShowPopup(true);
            }
        }, 3000);

        return () => clearTimeout(timeoutId);
    }, []);



    if (!showPopup) {
        return null;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%] fhd:h-[30rem] fhd:w-[40rem] hd:h-[24rem] hd:w-[30rem] text-center">
                <button className="absolute top-2 right-2 text-xl z-10 bg-white/75 rounded-full p-[10px]" onClick={closePopup}>
                    <AiOutlineClose />
                </button>
                <img loading="lazy" alt="upla dont miss" src={image} className="w-full top-0 left-0 absolute object-cover h-[50%] rounded-t-lg mb-4" />
                <h2 className="fhd:text-[42px] font-amatic hd:text-[34px] font-bold mt-[45%] mb-[1.4rem] z-10 text-[#57BEA4]">{t("popupTitle")}</h2>
                <button
                    onClick={() => { navigate('/club'); closePopup(); }}
                    className="bg-[#57BEA4] text-white py-2 px-4 rounded-full hover:bg-[#88e0c9] z-10"
                >
                    {t("discoverMore")}
                </button>
            </div>
        </div>
    );
};

export default Popup;