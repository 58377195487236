import React, { useEffect, useState } from "react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function SemesterInfo() {

    const { t,i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const [data, setData] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubInformation/get/${currentLanguage}`)
            .then(response => response.json())
            .then(data => {
                setData(data);
            })

    }, [localStorage.getItem("language")]);

    return (
        <div className={`w-full flex flex-col bg-[#58C2A6] ${currentLanguage==="ar" ? "font-nokta" : "font-amatic"} max-sm:mt-[8vh] sm:mt-[20vh]`}>
            {data && (
                <>
                    <h1 className="text-[#FB7F0D] font-bold fhd:text-[64px] hd:text-[54px] max-sm:text-[16px] mt-[4vh] ml-auto mr-auto">{data.semesterTitle} {data.semesterDuration}</h1>
                    <h2 className=" text-white 2k:text-[40px] fhd:text-[32px] hd:text-[26px] max-sm:text-[10px] mt-[5vh] max-sm:mt-[1.5vh] ml-auto mr-auto">
                        {t("ageInterval")}
                    </h2>
                    <Button title={t("registerNow")} onClick={() => { navigate("/club/form") }} className="z-[2] ml-auto mr-auto max-sm:text-[12px]  mt-[5vh] max-sm:mt-[1.5vh] mb-[4vh] max-sm:mb-[2vh] w-[14vw] max-sm:w-[8rem] rounded-full tracking-[6px]" />
                </>
            )}
        </div>
    )
}