import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SharedPage from "./SharedPage";
import { useTranslation } from "react-i18next";
import InputField from "../utils/components/club/InputField";
import TextArea from "../utils/components/club/TextArea";
import Button from "../utils/components/Button";

export default function AdminNoteDetails() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;


    const [data, setData] = useState({});

    const { id } = useParams();

    const [isDataUpdated, setIsDataUpdated] = useState(false);


    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customerNote/get/${id}`, {
            method: 'GET', 
            headers: {
              'Content-Type': 'application/json',
              'X-UID': sessionStorage.getItem("uid") 
            }
          })
            .then((res) => res.json())
            .then((data) => {
                setData(data);
            });
    }, [id]);

    const handleClick = () => {
        data.approved = true;
        setData(prevData => ({
            ...prevData,
            approved: true
        }));
        setIsDataUpdated(true);
    };

    useEffect(() => {
        if (isDataUpdated) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customerNote/updateCustomerNote`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-UID': sessionStorage.getItem("uid")
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((updatedData) => {
                    setData(updatedData);
                    setIsDataUpdated(false);
                });
        }
    }, [data, id, isDataUpdated])




    return (
        <SharedPage>
            <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="m-auto w-[70%] mb-[4%] flex flex-col">
                <h1 className="font-amatic font-bold fhd:text-[40px] hd:text-[34px] text-[#323E48]  mt-[6vh]">{t("guardian")}</h1>

                <InputField
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    type="text"
                    width={"w-[50%]"}
                    state={data.customerName}
                    placeHolder={t("name")}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    id="name"
                    readOnly={true}

                />
                <TextArea
                    className={`rounded-[15px] bg-white focus:bg-white border-[1px] border-black`}
                    width={"w-[100%]"}
                    type="text"
                    state={data.aboutChild === "" ? t("ntgToShow") : data.customerText}
                    dir={currentLanguage === "ar" ? "rtl" : "ltr"}
                    placeHolder={t("note")}
                    id="note"
                    readOnly={true}
                />
                {!data.approved && <Button
                    title={t("approved")}
                    className={`z-[2] ml-auto mr-auto mt-[5vh] w-[10vw] rounded-full tracking-[3px]`}
                    onClick={handleClick}
                />}
            </div>
        </SharedPage>
    )
}