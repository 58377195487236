import React, { useEffect, useState } from "react";
import SpecialOffers from "../utils/components/SpecialOffers";
import NavigationBar from "../utils/components/navigation/NavigationBar";
import TopImage from "../utils/components/TopImage";
import background from "../assets/gallery/DSC_8782.jpg"
import HomePageTitle from "../utils/components/HomePageTitle";
import Gallery from "../utils/components/Gallery";
import CopyRight from "../utils/components/footer/CopyRight";
import Footer from "../utils/components/footer/Footer";
import loader from "../assets/loader/packman.svg"
import LoveNotes from "../utils/components/LoveNotes";
import { useTranslation } from 'react-i18next';
import Popup from "../utils/PopUp";
import Button from "../utils/components/Button";
import { useNavigate } from "react-router-dom";
import ClubDef from "../utils/components/club/ClubDef";


export default function HomePage() {

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const currentLanguage = i18n.language

    const [animating, setAnimating] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="h-auto w-full overflow-y-hidden">
            <div className={`fixed z-[40] opacity-85 overflow-y-hidden flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[100vw] h-[100vh] bg-[#FB7F0D] ${animating ? "" : "hidden"}`}>
                <img loading="lazy" alt="loader" src={loader} className="m-auto" />
            </div>
            <SpecialOffers />
            <NavigationBar />
            <TopImage >
                <img loading="lazy" alt="upla arabic club" src={background} className="absolute object-cover sm:h-[75vh] max-sm:h-[15rem] w-full" />
                <h1 className={`font-bold z-10 mt-auto text-[#323E48] 2k:text-[64px] fhd:text-[54px] hd:text-[46px] ${currentLanguage === "en" ? "font-amatic mr-auto ml-[6rem] max-sm:mx-auto" : "font-hacenCasablanca ml-auto mr-[6rem] max-sm:mx-auto"} max-sm:text-[20px]`}>{t("learn")}</h1>
                <h2 className={`font-bold z-10 text-[#323E48] 2k:text-[46px] fhd:text-[34px] hd:text-[28px] mt-[0.5rem] mb-[0.5rem] ${currentLanguage === "en" ? "font-amatic mr-auto ml-[6rem] max-sm:mx-auto" : "font-hacenCasablanca ml-auto mr-[6rem] max-sm:mx-auto"} max-sm:text-[16px]`}>{t("discoverClub")}</h2>
                <Button title={t("discoverMore")} onClick={() => { navigate("/club") }} className={`z-[10] mb-auto ${currentLanguage === "en" ? "font-amatic mr-auto ml-[12rem] tracking-[4px] max-sm:mx-auto" : "font-hacenCasablanca ml-auto mr-[12rem] max-sm:mx-auto"} w-[14vw] max-sm:w-[8rem] max-sm:text-[12px] rounded-full `} />
            </TopImage>
            <HomePageTitle title={t("title")} />

            <ClubDef />

            <Gallery animating={animating} setAnimating={setAnimating} />
            {/* <LabelsHolder>

            </LabelsHolder> */}
            <LoveNotes />
            {/* <div className="mt-[10vh] overflow-y-hidden w-[80%] h-[70vh] flex flex-col mx-auto">
                <h1 className="font-amatic font-bold ml-auto mr-auto text-[64px]">Our latest blogs</h1>
                <div className="w-fit-content flex flex-row mt-[6vh] gap-x-60 ml-auto mr-auto">
                    <div className="flex flex-col w-fit-content w-[474px]">
                        <img loading="lazy" src={tips} className="fhd:w-[474px] ml-auto mr-auto fhd:h-[371px] rounded-[35px] cursor-pointer" />
                        <h1 className="font-amatic fhd:text-[32px] font-bold overflow-ellipsis overflow-hidden whitespace-nowrap max-w-[80%] mr-auto ml-auto">Our tips for kids education </h1>
                        <h2 className="font-amatic text-gray-400 fhd:text-[16px]  overflow-ellipsis overflow-hidden whitespace-nowrap w-fit-content mr-auto ml-auto">July 10, 2024</h2>
                        <p class="font-amatic text-[20px] max-w-[80%] overflow-hidden text-ellipsis max-h-[12vh] mx-auto text-center ">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
                        </p>
                    </div>
                    <div className="flex flex-col w-fit-content w-[474px]">
                        <img loading="lazy" src={tips} className="fhd:w-[474px] ml-auto mr-auto fhd:h-[371px] rounded-[35px] cursor-pointer" />
                        <h1 className="font-amatic fhd:text-[32px] font-bold overflow-ellipsis overflow-hidden whitespace-nowrap max-w-[80%] mr-auto ml-auto">Our tips for kids education </h1>
                        <h2 className="font-amatic text-gray-400 fhd:text-[16px]  overflow-ellipsis overflow-hidden whitespace-nowrap w-fit-content mr-auto ml-auto">July 10, 2024</h2>
                        <p class="font-amatic text-[20px] max-w-[80%] overflow-hidden text-ellipsis max-h-[12vh] mx-auto text-center ">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.
                        </p>
                    </div>
                </div>
            </div> */}
            <Footer />
            <CopyRight />
            <Popup />
        </div>
    )
}