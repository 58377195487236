import React from "react";
import { useTranslation } from "react-i18next";

export default function CopyRight() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const currentYear = new Date().getFullYear();

    return(
        <div className={`flex flex-row font-poppins font-bold 2k:text-[30px] fhd:text-[24px] hd:text-[18px] max-sm:text-[14px] max-sm:h-[3rem] text-[#323E48] h-[10vh] ${currentLanguage==="ar" && "flex-row-reverse"}`}>
            <p className={`text-[#FB7F0D] mt-auto mb-auto ${currentLanguage==="ar" ? "mr-auto" : "ml-auto"}`}><span>{currentLanguage==="en" ? "© " : ""} {currentYear}</span> {t("upla")} {currentLanguage==="en" ? "" : " ©"}</p>
            <p className="mt-auto mb-auto ml-[10px] mr-[10px]">-</p>
            <p className={`text-[#57BEA4] mt-auto mb-auto ${currentLanguage==="ar" ? "ml-auto" : "mr-auto"}`}>{t("copyRight")}</p>
        </div>
    )
}