import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { FormProvider } from './contexts/form/form.context';
import HomePage from './pages/HomePage';
import ClubPage from './pages/ClubPage';
import ClubFormPage from './pages/ClubFormPage';
import ClubPaimentPage from './pages/ClubPaimentPage';
import AdminPage from './pages/AdminPage';
import AdminFormPage from './pages/AdminFormPage';
import ExpressFeelingsPage from './pages/ExpressYourFeelings';
import AboutTheClubPage from './pages/AboutTheClubPage';
import LanguageProvider from './utils/LanguageProvider';
import AdminFormDetails from './pages/AdminFormDetails';
import LoginPage from './pages/LoginPage';
import AdminNotePage from './pages/AdminNotePage';
import AdminNoteDetails from './pages/AdminNoteDetails';
import NotFoundPage from './pages/NotFoundPage';

function App() {

  return (
    <FormProvider>
      <Router>
        <LanguageProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/expressfeelings" element={<ExpressFeelingsPage />} />
            <Route path="/admin" >
              <Route path="" element={<AdminPage />} />
              <Route path="forms" >
                <Route path="" element={<AdminFormPage />} />
                <Route path=":id" element={<AdminFormDetails />} />
              </Route>
              <Route path="note" >
                <Route path="" element={<AdminNotePage />} />
                <Route path=":id" element={<AdminNoteDetails />} />
              </Route>
            </Route>
            <Route path="/club" >
              <Route path="" element={<ClubPage />} />
              <Route path="about" element={<AboutTheClubPage />} />
              <Route path="form"  >
                <Route path="" element={<ClubFormPage />} />
                <Route path="paiment" element={<ClubPaimentPage />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </LanguageProvider>
      </Router>
    </FormProvider>
  );
}

export default App;
