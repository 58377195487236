import React, { useEffect } from 'react';
import enLogo from "../../../assets/logos/en-footer-logo.png"
import arLogo from "../../../assets/logos/ar-footer-logo.png"
import { FaLocationDot } from "react-icons/fa6";
import facebook from "../../../assets/social-media/facebook_.svg"
import instagram from "../../../assets/social-media/instagram.svg"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
function Footer() {

    const [isMobile, setIsMobile] = React.useState(false);

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const email = "contact@upla.ma".split("").reverse().join("");


    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width < 640);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`relative overflow-y-hidden flex flex-row max-sm:flex-col w-full h-[80vh] bg-[#F4F4F4] mt-[10vh] ${currentLanguage === "ar" && "flex-row-reverse"}`}>
            {!isMobile && <div className={`flex flex-col w-[10%] max-sm:w-[30%] ${currentLanguage === "ar" ? "mr-[10%]" : "ml-[10%]"}`}>
                <img loading="lazy" alt="upla" src={currentLanguage === "en" ? enLogo : arLogo} className={`mt-[50%] `} />
                <div className={`flex flex-row mt-[30%] ${currentLanguage === "ar" && "flex-row-reverse"}`}>
                    <FaLocationDot className='text-[#323E48] 2k:text-[62px] fhd:text-[56px] hd:text-[50px] max-sm:text-[2rem]' />
                    <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`font-amatic font-semibold 2k:text-[30px] fhd:text-[24px] hd:text-[20px] text-[#323E48] leading-[1.2] ${currentLanguage === "ar" ? "mr-[10%]" : "ml-[10%]"} `}><a href={"https://www.google.com/maps/place/ELITE+SCHOOL/@33.9406719,-6.8883661,17z/data=!3m1!4b1!4m6!3m5!1s0xda713d11e31ace5:0x3b7333127a86ad04!8m2!3d33.940672!4d-6.8837527!16s%2Fg%2F11h6r_q54y?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"} target="_blank" rel="noopener noreferrer">{t("locationAddress")}</a></p>
                </div>
            </div>}
            <div className={`max-sm:flex max-sm-flex-row ${currentLanguage === "ar" && "max-sm:flex-row-reverse"}`}>
                {isMobile && <div className={`flex flex-col w-[10%] max-sm:w-[30%] ${currentLanguage === "ar" ? "mr-[10%]" : "ml-[10%]"}`}>
                    <img loading="lazy" alt="upla" src={currentLanguage === "en" ? enLogo : arLogo} className={`mt-[50%] `} />
                    <div className={`flex flex-row mt-[30%] ${currentLanguage === "ar" && "flex-row-reverse"}`}>
                        <FaLocationDot className='text-[#323E48] 2k:text-[62px] fhd:text-[56px] hd:text-[50px] max-sm:text-[2rem]' />
                        <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`font-amatic font-semibold 2k:text-[30px] fhd:text-[24px] hd:text-[20px] text-[#323E48] leading-[1.2] ${currentLanguage === "ar" ? "mr-[10%]" : "ml-[10%]"} `}><a href={"https://www.google.com/maps/place/ELITE+SCHOOL/@33.9406719,-6.8883661,17z/data=!3m1!4b1!4m6!3m5!1s0xda713d11e31ace5:0x3b7333127a86ad04!8m2!3d33.940672!4d-6.8837527!16s%2Fg%2F11h6r_q54y?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"} target="_blank" rel="noopener noreferrer">{t("locationAddress")}</a></p>
                    </div>
                </div>}
                {isMobile &&
                    <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`flex flex-col mt-[14%] ${currentLanguage === "ar" ? "mr-[16%]" : "ml-[16%]"}`}>
                        <h1 className='font-amatic font-bold fhd:text-[32px] hd:text-[26px] text-[#323E48] ml-[-3%]'>
                            {t("getInTouch")}
                        </h1>
                        <ul className="flex flex-col w-fit-content">
                            <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                                <a href={`mailto:${email.split("").reverse().join("")}`}>contact@upla.ma</a>
                            </li>
                            <li dir={currentLanguage === "ar" && "ltr"} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px]  font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer  group">
                                <a href='tel:+212682368913' className='hover:text-[#FB7F0D] max-sm:text-[12px]'>+212 6 82 36 89 13</a> <span> / <a className='hover:text-[#FB7F0D] max-sm:text-[12px]' href='tel:+212537561820'>+212 5 37 56 18 20</a></span>
                            </li>
                            <li className={`relative flex flex-row font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic mt-[-4px] cursor-pointer hover:text-[#FB7F0D] group`}>
                                <a href='https://www.instagram.com/upla.ma?igsh=MTE3czB1YmM4cjRrcg%3D%3D' target="_blank" rel="noopener noreferrer" className={`w-full h-full flex flex-row ${currentLanguage === "ar" && "flex-row-reverse"}`}>
                                    <img loading="lazy" alt="upla.ma instagram" src={instagram} className=' mt-auto mb-auto h-[50%] max-sm:' /> <span className='mt-auto mb-auto ml-[6%]'>{t("uplaInstagram")}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                }
            </div>
            {!isMobile && <div className={`flex flex-col mt-[5%] ${currentLanguage === "ar" ? "mr-[8%]" : "ml-[8%]"}`}>
                <h1 className='font-amatic font-bold fhd:text-[32px] hd:text-[26px] text-[#323E48] ml-[-3%]'>
                    {t("quickLinks")}
                </h1>
                <ul dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="flex flex-col w-fit-content">
                    <li onClick={() => { navigate("/home") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("home")}
                    </li>
                    <li onClick={() => { navigate("/club") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("club")}
                    </li>
                    {/* <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        All products
                    </li>
                    <li className="relative flex font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Books
                    </li>
                    <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Toys
                    </li>
                    <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Gifts
                    </li> */}
                    {/* <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Blog
                    </li> */}
                    {/* <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Printables
                    </li> */}
                    <li onClick={() => { navigate("/expressfeelings") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("expressYourFeling")}
                    </li>
                    <li onClick={() => { navigate("/login") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("login")}
                    </li>
                    <li onClick={() => { navigate("/club/about") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("aboutUs")}
                    </li>
                </ul>
            </div>}
            <div className={`max-sm:flex max-sm-flex-row ${currentLanguage === "ar" && "flex-row-reverse"} max-sm:ml-[1rem]`}>
                {isMobile && <div className={`flex flex-col mt-[5%] ${currentLanguage === "ar" ? "mr-[8%]" : "ml-[8%]"}`}>
                    <h1 className='font-amatic font-bold fhd:text-[32px] hd:text-[26px] text-[#323E48] ml-[-3%]'>
                        {t("quickLinks")}
                    </h1>
                    <ul dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="flex flex-col w-fit-content">
                        <li onClick={() => { navigate("/home") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                            {t("home")}
                        </li>
                        <li onClick={() => { navigate("/club") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                            {t("club")}
                        </li>
                        {/* <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        All products
                    </li>
                    <li className="relative flex font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Books
                    </li>
                    <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Toys
                    </li>
                    <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Gifts
                    </li> */}
                        {/* <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Blog
                    </li> */}
                        {/* <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        Printables
                    </li> */}
                        <li onClick={() => { navigate("/expressfeelings") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                            {t("expressYourFeling")}
                        </li>
                        <li onClick={() => { navigate("/club/about") }} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] hd:text-[18px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                            {t("aboutUs")}
                        </li>
                    </ul>
                </div>}
                {isMobile && <div className={`flex flex-col mt-[5%] ${currentLanguage === "ar" ? "mr-[16%]" : "ml-[16%]"}`}>
                    <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className='font-amatic font-bold fhd:text-[32px] hd:text-[26px] text-[#323E48] ml-[-3%]'>
                        {t("policies")}
                    </h1>
                    <ul dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="flex flex-col w-fit-content">
                        <li className="relative font-bold text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                            {t("termsOfService")}
                        </li>
                        <li className="relative font-bold text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                            {t("privacyPolicy")}
                        </li>
                        <li className="relative font-bold text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                            {t("shippingPolicy")}
                        </li>
                        <li className="relative flex font-bold text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                            {t("refundPolicy")}
                        </li>
                    </ul>

                </div>}
            </div>
            {!isMobile && <div className={`flex flex-col mt-[5%] ${currentLanguage === "ar" ? "mr-[16%]" : "ml-[16%]"}`}>
                <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className='font-amatic font-bold fhd:text-[32px] hd:text-[26px] text-[#323E48] ml-[-3%]'>
                    {t("policies")}
                </h1>
                <ul dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="flex flex-col w-fit-content">
                    <li className="relative font-bold text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("termsOfService")}
                    </li>
                    <li className="relative font-bold text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("privacyPolicy")}
                    </li>
                    <li className="relative font-bold text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("shippingPolicy")}
                    </li>
                    <li className="relative flex font-bold text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        {t("refundPolicy")}
                    </li>
                </ul>

            </div>}
            {!isMobile && <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`flex flex-col mt-[5%] ${currentLanguage === "ar" ? "mr-[16%]" : "ml-[16%]"}`}>
                <h1 className='font-amatic font-bold fhd:text-[32px] hd:text-[26px] text-[#323E48] ml-[-3%]'>
                    {t("getInTouch")}
                </h1>
                <ul className="flex flex-col w-fit-content">
                    <li className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer hover:text-[#FB7F0D] group">
                        <a href={`mailto:${email.split("").reverse().join("")}`}>contact@upla.ma</a>
                    </li>
                    <li dir={currentLanguage === "ar" && "ltr"} className="relative font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px]  font-amatic fhd:mt-[6px] hd:mt-[3px] cursor-pointer  group">
                        <a href='tel:+212682368913' className='hover:text-[#FB7F0D]'>+212 6 82 36 89 13</a> <span> / <a className='hover:text-[#FB7F0D]' href='tel:+212537561820'>+212 5 37 56 18 20</a></span>
                    </li>
                    <li className={`relative flex flex-row font-bold w-fit-content text-[#323E48] 2k:text-[24px] fhd:text-[24px] font-amatic mt-[-4px] cursor-pointer hover:text-[#FB7F0D] group`}>
                        <a href='https://www.instagram.com/upla.ma?igsh=MTE3czB1YmM4cjRrcg%3D%3D' target="_blank" rel="noopener noreferrer" className={`w-full h-full flex flex-row ${currentLanguage === "ar" && "flex-row-reverse"}`}>
                            <img loading="lazy" alt="upla.ma instagram" src={instagram} className=' mt-auto mb-auto h-[50%]' /> <span className='mt-auto mb-auto ml-[6%]'>{t("uplaInstagram")}</span>
                        </a>
                    </li>
                </ul>
            </div>}
            <div className={`absolute bg-transparent bottom-10 w-[90%]  ${currentLanguage === "ar" ? "flex-row-reverse mr-[5%] max-sm:ml-[5%]" : "ml-[5%]"} fhd:py-[20px] hd:py-[2px] border-t-[4px] border-b-[4px] border-[#323E48] flex flex-row`}>
                <p className={`font-amatic text-[#323E48] fhd:text-[36px] hd:text-[28px] ${currentLanguage === "ar" ? "mr-auto" : "ml-auto"} mt-auto mb-auto tracking-[6px]`}>{t("followUs")}</p>
                <a href="https://www.facebook.com/profile.php?id=61561638730482" target="_blank" rel="noopener noreferrer" className={` ${currentLanguage === "ar" ? "mr-[15%] ml-[8%]" : "ml-[15%] mr-[8%]"} mt-auto mb-auto`}>
                    <img loading="lazy" alt="upla.ma facebook" src={facebook} className=' cursor-pointer hover:opacity-75 max-sm:h-[3rem] fhd:h-[80px] hd:h-[60px]' />
                </a>
                <a href="https://www.instagram.com/upla.ma?igsh=MTE3czB1YmM4cjRrcg%3D%3D" target="_blank" rel="noopener noreferrer" className={`${currentLanguage === "ar" ? "ml-auto" : "mr-auto"} mt-auto mb-auto`}>
                    <img loading="lazy" alt="upla.ma instagram" src={instagram} className='hover:opacity-75 cursor-pointer max-sm:h-[2.5rem] fhd:h-[70px] hd:h-[50px]' />
                </a>
            </div>
        </div>
    )
}
export default React.memo(Footer);
