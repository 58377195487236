import { createContext, useState } from "react";

const FormContext = createContext({})

export default FormContext

export const FormProvider = ({ children }) => {
  const [currentForm, setCurrentForm] = useState(null)

  const value = {
    currentForm,
    setCurrentForm,
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}