import React, { useState, useEffect } from 'react';
import { images } from "../../assets/gallery/images";
import { useTranslation } from 'react-i18next';

export default function Gallery({animating, setAnimating}) {

    const { t,i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const [imagesState] = useState(images);
    const [currentImage, setCurrentImage] = useState(0);


    const handleLeftClick = () => {
        if (animating) return;
        setAnimating(true);
        setCurrentImage((prev) => (prev === 0 ? imagesState.length - 1 : prev - 1));
    };

    const handleRightClick = () => {
        if (animating) return;
        setAnimating(true);
        setCurrentImage((prev) => (prev === imagesState.length - 1 ? 0 : prev + 1));
    };

    useEffect(() => {
        if (animating) {
            const timeout = setTimeout(() => setAnimating(false), 2000);
            return () => clearTimeout(timeout);
        }
    }, [animating]);

    return (
        <div className="flex flex-col sm:mt-[15vh] max-sm:mt-[2vh]">
            <div className="flex flex-row">
                <div className="w-full h-[1px] bg-[#323E48] mt-auto mb-auto ml-[5%]" />
                <p className={`${currentLanguage==="ar" ? "font-hacenCasablanca " : "font-amatic "} font-bold text-[54px] max-sm:text-[18px] text-[#323E48] ml-[2%] mr-[2%]`}>{t("gallery")}</p>
                <div className="w-full h-[1px] bg-[#323E48] mt-auto mb-auto mr-[5%]" />
            </div>
            <div className="relative flex flex-row sm:mt-[10vh] max-sm:mt-[2rem] w-[90%] overflow-hidden ml-auto mr-auto">
                <img loading="lazy"
                    alt="arabic club photos"
                    src={images[currentImage === 0 ? images.length - 1 : currentImage - 1]}
                    className={`absolute 2k:w-[740px] fhd:w-[697px] hd:w-[540px] max-sm:w-[154px] 2k:h-[740px] fhd:h-[697px] hd:h-[540px] max-sm:h-[154px] block ml-[-20%] 2k:ml-[-14%] max-sm:ml-[-22%] fhd:mr-[168px] rounded-[40px] max-sm:rounded-[10px] transition-oapcity duration-[4000ms] ${animating ? 'opacity-0' : 'opacity-25'}`}
                />
                <img loading="lazy"
                    alt="arabic club photos"
                    src={images[currentImage]}
                    className={`absolute 2k:w-[740px] fhd:w-[697px] hd:w-[540px] max-sm:w-[154px] left-[29%] 2k:left-[33%] 2k:h-[740px] fhd:h-[697px] hd:h-[540px] max-sm:h-[154px] block rounded-[40px] max-sm:rounded-[10px] object-cover transition-oapcity duration-[4000ms] ${animating ? 'opacity-0' : 'opacity-100'}`}
                />
                <img loading="lazy"
                    alt="arabic club photos"
                    src={images[currentImage === images.length - 1 ? 0 : currentImage + 1]}
                    className={`2k:w-[740px] fhd:w-[697px] hd:w-[540px] max-sm:h-[154px] 2k:h-[740px] fhd:h-[697px] hd:h-[540px] max-sm:w-[154px] block rounded-[40px] max-sm:rounded-[10px] max-sm:ml-[72vw] 2k:ml-[1780px] fhd:ml-[1350px] hd:ml-[940px] transition-oapcity duration-[4000ms] ${animating ? 'opacity-0' : 'opacity-25'}`}
                />
                <div
                    className="absolute w-[72px] h-[72px] max-sm:text-[22px] max-sm:w-[1.5rem] max-sm:h-[1.5rem] flex bg-white text-black text-[52px] border border-black rounded-full mt-[18%] 2k:mt-[15%] hover:opacity-75 cursor-pointer ml-[8%]"
                    onClick={handleLeftClick}
                >
                    <p className="mt-[-8px] ml-[15px] max-sm:mt-[-0.4rem] max-sm:ml-[0.35rem]">&lt;</p>
                </div>
                <div
                    className="absolute w-[72px] h-[72px] max-sm:text-[22px]  max-sm:w-[1.5rem] max-sm:h-[1.5rem]  flex bg-white text-black text-[52px] border border-black rounded-full mt-[18%] 2k:mt-[15%] hover:opacity-75 cursor-pointer ml-[88%]"
                    onClick={handleRightClick}
                >
                    <p className="mt-[-8px] ml-[20px] max-sm:mt-[-0.4rem] max-sm:ml-[0.4rem]">&gt;</p>
                </div>
            </div>
        </div>
    )
}