import React, { useEffect, useState } from "react";
import { PiShootingStarFill } from "react-icons/pi";
import { AiFillStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";

export default function HomePageTitle({ title }) {

    const { i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width < 640);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        
    }, []);

    return (
        <div className={`w-full flex flex-col mt-[-15vh]  max-sm:mt-[-2rem] overflow-x-hidden`}>
            <div className="flex flex-row relative">
                {!isMobile && <div className={`w-[12px] h-[12px] bg-[#57BEA4] max-sm:opacity-0 max-sm:mt-[20vh] fhd:mt-[28vh] hd:mt-[24vh] rounded-full ml-[1vw]`} />}
                <PiShootingStarFill className={`2k:text-[352px] max-sm:text-[86px] max-sm:ml-[1%] 2k:ml-[2%] fhd:text-[300px]  hd:text-[256px] text-[#57BEA4] `} />
                {!isMobile && <div className={`w-[12px] h-[12px] max-sm:opacity-0 bg-[#FB7F0D] fhd:mt-[25vh] hd:mt-[20vh] rounded-full ml-[1vw]`} />}
                {!isMobile && <div className={`w-[12px] h-[12px] max-sm:opacity-0 bg-[#FFE358] mt-[20vh] rounded-full ml-[8vw]`} />}
                <AiFillStar className={`text-[30px] max-sm:ml-[21%] max-sm:mt-[2.3rem] max-sm:text-[1rem] max-sm:mt-[2rem] text-[#FFE358] sm:mt-[23vh] sm:ml-[14vw]`} />
                {!isMobile && <div className={`w-[12px] h-[12px] max-sm:opacity-0 bg-[#FB7F0D] mt-[20vh] rounded-full ml-[10vw]`} />}
                <AiFillStar className={`text-[30px] max-sm:ml-[18%] max-sm:mt-[2.8rem] max-sm:text-[1rem] max-sm:mt-[1.6rem] text-[#FB7F0D] sm:mt-[23vh] sm:ml-[14vw]`} />
                {!isMobile && <div className={`w-[12px] h-[12px] max-sm:opacity-0 bg-[#57BEA4] mt-[18vh] rounded-full ml-[12vw]`} />}
                <PiShootingStarFill className={`2k:text-[352px] max-sm:ml-[13%] max-sm:text-[86px] fhd:text-[300px] hd:text-[256px] max-sm:mr-[4%] text-[#FFE358] `} style={{ transform: "rotate(-90deg)" }} />
            </div>
            <div className={`flex flex-row relative ${currentLanguage === "ar" ?  "font-nokta" : "font-amatic"} fhd:mt-[-2vh] hd:mt-[-5vh]`}>
                <h1 className={`font-bold  2k:text-[76px] fhd:text-[64px] hd:text-[56px] max-sm:text-[1.6rem] text-[#323E48] ml-auto mr-auto`}>{title}</h1>
            </div>
            <div className="flex flex-row relative">
            {!isMobile && <div className={`w-[42px] h-[42px] max-sm:opacity-0 bg-[#FB7F0D] mt-[5vh] rounded-full ml-[1vw]`} />}
                <AiFillStar className={`text-[30px] max-sm:ml-[15%] max-sm:text-[1rem] max-sm:mt-[1.6rem] text-[#FFE358] sm:mt-[2vh] sm:ml-[8vw]`} />
                <AiFillStar className={`text-[30px] max-sm:ml-[20%] max-sm:text-[1rem] max-sm:mt-[2rem] text-[#57BEA4] sm:mt-[4vh] sm:ml-[8vw]`} />
                {!isMobile && <div className={`w-[12px] h-[12px] max-sm:opacity-0 bg-[#57BEA4] mt-[3vh] rounded-full ml-[16vw]`} />}
                <AiFillStar className={`text-[26px] max-sm:ml-[20%] max-sm:text-[1rem] max-sm:mt-[2rem] text-[#FB7F0D] sm:mt-[6vh] sm:ml-[14vw]`} />
                {!isMobile && <div className={`w-[12px] h-[12px] max-sm:opacity-0 bg-[#FB7F0D] sm:mt-[2vh] rounded-full sm:ml-[10vw]`} />}
                <AiFillStar className={`text-[30px] max-sm:ml-[15%] max-sm:text-[1rem] max-sm:mt-[1.4rem] text-[#FFE358] s,:mt-[4vh] sm:ml-[14vw]`} />
                {!isMobile && <div className={`w-[42px] h-[42px] max-sm:opacity-0 bg-[#FB7F0D] mt-[1vh] rounded-full ml-[14vw]`} />}
            </div>

        </div>
    )
}