import React from "react";
import yellowStar from "../../assets/rating/yellow-star.svg";
import whiteStar from "../../assets/rating/white-star.svg";
export default function Stars({ rating, className }) {
    return (
        <div className={`flex ${className}`}>
            {[...Array(5)].map((star, i) => {
                const ratingValue = i + 1;
                return (
                    <label key={i}>
                        {ratingValue <= rating ? (
                            <img loading="lazy" alt="upla rating" src={yellowStar} className="max-sm:w-[0.8rem] "/>
                        ) : (
                            <img loading="lazy" alt="upla rating" src={whiteStar} className="max-sm:w-[0.8rem] "/>
                        )}
                    </label>
                )
            })}
        </div>
    )
}