import React, { useEffect } from "react";
import SpecialOffers from "../utils/components/SpecialOffers";
import NavigationBar from "../utils/components/navigation/NavigationBar";
import TopImage from "../utils/components/TopImage";
import background from "../assets/club/topImage.svg"
import Button from "../utils/components/Button";
import Titles from "../utils/components/Titles";
import ClubDef from "../utils/components/club/ClubDef";
import SemesterInfo from "../utils/components/club/SemesterInfo";
import TimeInfo from "../utils/components/club/TimeInfo";
import LocationInfo from "../utils/components/club/LocationInfo";
import RegistrationsInfo from "../utils/components/club/RegistrationsInfo";
import OurTeachers from "../utils/components/club/OurTeachers";
import TeacherVideo from "../utils/components/club/TeacherVideo";
import Footer from "../utils/components/footer/Footer";
import CopyRight from "../utils/components/footer/CopyRight";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ClubPage() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="w-full h-auto">
            <SpecialOffers />
            <NavigationBar />
            <TopImage>
                <img loading="lazy" alt="what is upla" src={background} className="absolute object-cover sm:h-[75vh] max-sm:h-[15rem] w-full" />
                <div className={`fhd:w-[34rem] hd:w-[26rem] text-center fhd:pt-[8rem] hd:pt-[5rem] max-sm:mt-[2.5rem] max-sm:h-[10rem] max-sm:w-[10rem] max-sm:pt-[2rem] pl-[2rem] pr-[2rem] fhd:h-[34rem] hd:h-[26rem] bg-[#FFE358]/50 z-[2] ${currentLanguage === "ar" && "flex-row-reverse"} m-auto rounded-full`}>
                <h1 className="font-amatic font-bold text-stroke text-stroke-gray text-[#57BEA4] sm:text-[64px]  mt-auto ml-auto mr-auto ">{t("upla")} <span className="text-[#FB7F0D]">{t("club")}</span></h1>
                    <h1 className="font-amatic font-bold text-stroke text-stroke-gray text-white sm:text-[40px]  ml-auto mr-auto mb-auto">{t("clubOp")}</h1>
                </div>
            </TopImage>
            <Titles color={"#57BEA4"} title={t("titleClub")} margin={"mt-[20vh] max-sm:mt-[4vh]"} />
            <TeacherVideo />
            <ClubDef />
            <Titles color={"#323E48"} title={t("OurTeachers")} margin={"mt-[10vh] max-sm:mt-[2vh]"} />
            <OurTeachers />
            <SemesterInfo />
            <Titles color={"#323E48"} title={t("timing")} margin={"mt-[10vh] max-sm:mt-[2vh]"} />
            <TimeInfo />
            <Titles color={"#323E48"} title={t("location")} margin={"mt-[10vh] max-sm:mt-[2vh]"} />
            <LocationInfo />
            <RegistrationsInfo />
            <div className="w-full flex flex-col mt-[10vh] max-sm:mt-[4vh] mb-[20vh] max-sm:mb-[4vh]">
                <h1 className={`text-[#323E48] ${currentLanguage==="ar" ? "font-nokta" : "font-amatic"} font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[54px] max-sm:mt-[1vh] mt-[4vh] ml-auto mr-auto`}>{t("dontMiss")}</h1>
                <h2 className={` text-[#323E48] ${currentLanguage==="ar" ? "font-hacenCasablanca" : "font-amatic"} 2k:text-[38px] fhd:text-[32px] hd:text-[26px] max-sm:mt-[1.2vh] mt-[5vh] ml-auto mr-auto`}>
                    {t("happyToSee")}
                </h2>
                <Button title={t("registerNow")} onClick={() => { navigate("/club/form") }} className="z-[2] ml-auto mr-auto mt-[5vh] max-sm:mt-[2vh] mb-[4vh] w-[14vw] max-sm:w-[8rem] max-sm:text-[12px] rounded-full tracking-[4px]" />
            </div>
            <Footer />
            <CopyRight />
        </div>
    )
}