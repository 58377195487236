import React from "react";
import image from "../../../assets/club/definitionImage.svg"
import { Trans } from 'react-i18next';

import { useTranslation } from 'react-i18next';

export default function ClubDef() {

    const { t,i18n } = useTranslation();

    const currentLanguage = i18n.language;

    return (
        <div className={`flex flex-row max-sm:flex-col ${currentLanguage==="ar" && "flex-row-reverse"} w-[86%] ml-auto mr-auto mt-[6vh] gap-x-20`}>
            <img loading="lazy" alt="what is upla arabic club" src={image} className="max-sm:mx-auto fhd:w-[545px] fhd:h-[660px] hd:w-[425px] hd:h-[510px] max-sm:w-[295px] max-sm:h-[260px]" />
            <div className="flex flex-col mt-[0.5%] max-sm:mt-[1.2rem]">
                <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`font-amatic ${currentLanguage==="ar" && "ml-auto"} font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] max-sm:text-[16px] text-[#323E48] w-fit-content cursor-default hover:text-[#FB7F0D]`}>{t("whatsUplaClub")}</h1>
                <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] max-sm:text-[12px] whitespace-pre-line"><Trans i18nKey="clubWelcome" /></p>
            </div>
        </div>
    )
}