import React, { useEffect } from "react";
import NavigationBar from "../utils/components/navigation/NavigationBar";
import SpecialOffers from "../utils/components/SpecialOffers";
import TopImage from "../utils/components/TopImage";
import background from "../assets/club/topImage.svg"
import Footer from "../utils/components/footer/Footer";
import CopyRight from "../utils/components/footer/CopyRight";
import { Trans, useTranslation } from "react-i18next";
import HomePageTitle from "../utils/components/HomePageTitle";
import Titles from "../utils/components/Titles";
import ClubDef from "../utils/components/club/ClubDef";
import workshopImage1 from "../assets/gallery/DSC_8897.jpg";
import workshopImage2 from "../assets/gallery/Copie de IMK05663.jpg"
import workshopImage3 from "../assets/gallery/Copie de IMK05828.jpg"
import workshopImage4 from "../assets/gallery/Copie de IMK05695.jpg"
import workshopQuran from "../assets/gallery/QuranWorkshop.jpg"
import Workshop from "../utils/components/club/workshops/Workshop";

export default function AboutTheClubPage() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <div className="w-full h-auto overflow-x-hidden">
            <SpecialOffers />
            <NavigationBar />
            <TopImage>
                <img loading="lazy" alt="upla club" src={background} className="absolute object-cover sm:h-[75vh] max-sm:h-[15rem] w-full" />
                <div className={`fhd:w-[34rem] hd:w-[26rem] text-center fhd:pt-[8rem] hd:pt-[5rem] max-sm:mt-[2.5rem] max-sm:h-[10rem] max-sm:w-[10rem] max-sm:pt-[2rem] pl-[2rem] pr-[2rem] fhd:h-[34rem] hd:h-[26rem] bg-[#FFE358]/50 z-[2] ${currentLanguage === "ar" && "flex-row-reverse"} m-auto rounded-full`}>
                    <h1 className="font-amatic font-bold text-stroke text-stroke-gray text-[#57BEA4] sm:text-[64px]  mt-auto ml-auto mr-auto ">{t("upla")} <span className="text-[#FB7F0D]">{t("club")}</span></h1>
                    <h1 className="font-amatic font-bold text-stroke text-stroke-gray text-white sm:text-[40px]  ml-auto mr-auto mb-auto">{t("clubOp")}</h1>
                </div>
            </TopImage>
            <HomePageTitle title={t("About our club")} />
            <Titles color={"#57BEA4"} title={t("titleClub")} margin={"mt-[10vh] max-sm:mt-[3vh]"} />
            <ClubDef />
            <Titles color={"#57BEA4"} title={t("workshop")} margin={"mt-[10vh] max-sm:mt-[3vh]"} />
            <Workshop src={workshopQuran} title={t("quranWorkshop.title")} dangerouslySetInnerHTML={{ __html: t("quranWorkshop.description")}} />
            <Workshop src={workshopImage1} title={t("workshopArt")} description={"workshopArtDescription"} />
            <Workshop src={workshopImage2} title={t("workshopReading")} description={"workshopReadingDescription"} />
            <Workshop src={workshopImage3} title={t("workshopCommunication")} description={"workshopCommunicationDescription"} />
            <Workshop src={workshopImage4} title={t("workshopLittleCreator")} description={"workshopLittleCreatorDescription"} />
            <div className={`flex flex-row ${currentLanguage === "ar" && "flex-row-reverse"} w-fit-content ml-auto mr-auto mt-[6vh] gap-x-20`}>
                <div className="flex flex-col mt-[0.5%]">
                    <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`${currentLanguage==="ar" ? "font-nokta" : "font-amatic"}  mx-auto  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] text-[#323E48] w-fit-content cursor-default hover:text-[#FB7F0D]`}>{t("why.ourTeam")}</h1>
                    <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify mx-auto w-[90%] max-sm:w-[70%] mt-[4%] fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] whitespace-pre-line"><Trans i18nKey={t("answer.team")} /></p>
                </div>
            </div>
            <div className={`flex flex-row ${currentLanguage === "ar" && "flex-row-reverse"} w-[86%] ml-auto mr-auto mt-[6vh] gap-x-20`}>
                <div className="flex flex-col mt-[0.5%]">
                    <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`${currentLanguage==="ar" ? "font-nokta" : "font-amatic"} mx-auto  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] text-[#323E48] w-fit-content  cursor-default hover:text-[#FB7F0D]`}>{t("uplaFun")}</h1>
                    <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify mx-auto w-[80%] mt-[4%] fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] whitespace-pre-line"><Trans i18nKey={t("uplaFunDescription")} /></p>
                </div>
            </div>
            <div className={`flex flex-row ${currentLanguage === "ar" && "flex-row-reverse"} w-fit-content ml-auto mr-auto mt-[6vh] gap-x-20`}>
                <div className="flex flex-col mt-[0.5%]">
                    <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`${currentLanguage==="ar" ? "font-nokta" : "font-amatic"} mx-auto  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] text-[#323E48] w-fit-content cursor-default hover:text-[#FB7F0D]`}>{t("joinUs")}</h1>
                    <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify mx-auto  mt-[4%] fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] max-sm:w-[70%] whitespace-nowrap max-sm:whitespace-normal"><Trans i18nKey={t("joinUsDescription")} /></p>
                </div>
            </div>
            <div className={`flex flex-row ${currentLanguage === "ar" && "flex-row-reverse"} w-fit-content ml-auto mr-auto  mt-[6vh] gap-x-20`}>
                <div className="flex flex-col mt-[0.5%]">
                    <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`${currentLanguage==="ar" ? "font-nokta" : "font-amatic"} mx-auto  font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] text-[#323E48] w-fit-content cursor-default hover:text-[#FB7F0D]`}>{t("contactUs")}</h1>
                    <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify mx-auto  mt-[4%] fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] max-sm:w-[70%] whitespace-nowrap max-sm:whitespace-normal"><Trans i18nKey={t("contactUsDescription")} /></p>
                </div>
            </div>
            <ul className="list-disc list-inside flex flex-col w-fit-content mx-auto mt-[2vh] justify-center gap-x-10">
                <li dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] font-bold">{t("phone1")} {currentLanguage==="ar" && ":"} <span dir={currentLanguage === "ar" && "ltr" } className="font-normal"><a href='tel:+212682368913' className='hover:text-[#FB7F0D] max-sm:text-[12px]'>+212 6 82 36 89 13</a> <span> / <a className='hover:text-[#FB7F0D] max-sm:text-[12px]' href='tel:+212537561820'>+212 5 37 56 18 20</a></span></span></li>
                <li dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] font-bold">{t("email1")} {currentLanguage==="ar" && ":"} <span  className="font-bold w-fit-content text-[#323E48] font-amatic cursor-pointer hover:text-[#FB7F0D]"><a href='mailto:contact@upla.ma'>contact@upla.ma</a></span></li>
                <li dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify fhd:text-[32px] hd:text-[23px] text-[#323E48] mt-[0.4%] font-bold">{t("location1")} {currentLanguage==="ar" && ":"} <span className="font-amatic font-semibold cursor-pointer text-[#323E48] hover:text-[#FB7F0D]"><a href={"https://www.google.com/maps/place/ELITE+SCHOOL/@33.9406719,-6.8883661,17z/data=!3m1!4b1!4m6!3m5!1s0xda713d11e31ace5:0x3b7333127a86ad04!8m2!3d33.940672!4d-6.8837527!16s%2Fg%2F11h6r_q54y?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"} target="_blank" rel="noopener noreferrer">{t("locationAddress")}</a></span></li>
            </ul>
            <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic text-justify mx-auto w-fit-content mt-[2vh] fhd:text-[32px] hd:text-[23px] text-[#323E48] max-sm:w-[70%] whitespace-nowrap max-sm:whitespace-normal"><Trans i18nKey={t("website")} /></p>
            <Footer />
            <CopyRight />
        </div>
    )
}