import { useTranslation } from "react-i18next";

export default function TextArea({className,readOnly,placeHolder,state,width,id,handleOnChange,type,dir}) {
    
    const {  i18n } = useTranslation();

    const currentLanguage = i18n.language;

    return (
        <div className={`input-container fhd:mt-[3vh] hd:mt-[6vh] max-sm:mt-[2rem] mb-[30vh] max-sm:mb-[1.2rem] ${width}`}>
            <textarea readOnly={readOnly} type={type} value={state} id={id} dir={dir} className={`${currentLanguage==="en"? "floating-input":"floating-input-ar"} min-h-[40vh] p-6 font-amatic resize-none 2k:text-[30px] fhd:text-[24px] hd:text-[18px] max-sm:text-[12px] ${className}`} placeholder=" " onChange={handleOnChange}/>
            <label for={id} className={`${currentLanguage==="en"? "floating-label":"floating-label-ar"} 2k:text-[30px] fhd:text-[24px] max-sm:top-[6%] font-amatic hd:text-[18px] max-sm:text-[10px] ${type==="number" ? "ml-[42.5%]":""}`}>{placeHolder} </label>
        </div>
    )
}