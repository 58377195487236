import React, { useEffect, useState } from "react";
import SpecialOffers from "../utils/components/SpecialOffers";
import NavigationBar from "../utils/components/navigation/NavigationBar";
import TopImage from "../utils/components/TopImage";
import background from "../assets/club/topImage.svg"
import Footer from "../utils/components/footer/Footer";
import CopyRight from "../utils/components/footer/CopyRight";
import image from "../assets/club/DSC_8772.jpg"
import Button from "../utils/components/Button";
import useFormContext from "../contexts/form/useFormContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ClubPaimentPage() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const { currentForm } = useFormContext();

    const [isRegistred, setIsRegistred] = useState(false)

    const [registrationType, setRegistrationType] = useState("")
    const [feePrice, setFeePrice] = useState(0)

    const [shake,setShake] = useState(false)

    const [data, setData] = useState()


    const navigate = useNavigate()


    const [checkedItems, setCheckedItems] = useState({
        full: false,
        registration: false,
        one: false,
        trial:false,
    });

    const [numSessions, setNumSessions] = useState(1);


    useEffect(() => {
        window.scrollTo(0, 0);
        if (currentForm) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubForm/getByGuardianAndChild/${currentForm.guardianFirstName}/${currentForm.guardianSecondName}/${currentForm.childFirstName}/${currentForm.childSecondName}`)
                .then(response => response.json())
                .then(data => {
                    setIsRegistred(data)
                })
                .catch(error => console.error('Error:', error));
        }
        else {
            navigate("/club/form")
        }

    }, []);


    const handleCheck = (item) => {
        if (item === "registration" && isRegistred) {
            return;
        }

        if (item === "one") {
            setCheckedItems({
                full: false,
                registration: true,
                one: !checkedItems.one,
            });
        }

        if (item === "trial") {
            setCheckedItems({
                full: false,
                registration: false,
                one: false,
                trial: true,
            });
        }

        if (item === "full") {
            setCheckedItems({
                full: !checkedItems.full,
                registration: false,
                one: false,
            });
        }
    };


    useEffect(() => {
        if (checkedItems.trial) {
            setRegistrationType("Free Trial");
            setFeePrice(150);
        } else if (checkedItems.full) {
            setRegistrationType("Full Semester");
            setFeePrice(2100);
        } else if (checkedItems.one && !isRegistred) {
            setRegistrationType("Sessions And Registration");
            setFeePrice(numSessions * 300 + 250);
        } else if (checkedItems.one) {
            setRegistrationType("Sessions")
            setFeePrice(numSessions * 300);
        } else {
            setRegistrationType("");
            setFeePrice(0);
        }
    }, [checkedItems, numSessions]);

    const handleSubmit = (e) => {
        e.preventDefault();


        if (feePrice === 0) {
            setShake(true);
            setTimeout(() => setShake(false), 500);
        } else {

            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubForm/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...currentForm,
                    registrationType,
                    feePrice
                }),
            })
                .then(response => response.json())
                .then(data => {
                });

            navigate("/club")
        }

    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clubInformation/get/${currentLanguage}`)
            .then(response => response.json())
            .then(data => {
                setData(data);
            })

    }, [localStorage.getItem("language")]);


    return (
        <div className="w-full h-auto">
            <SpecialOffers />
            <NavigationBar />
            <TopImage>
                <img loading="lazy" alt="club" src={background} className="absolute object-cover sm:h-[75vh] max-sm:h-[15rem] w-full" />
                <div className={`fhd:w-[34rem] hd:w-[26rem] text-center fhd:pt-[8rem] hd:pt-[5rem] max-sm:mt-[2.5rem] max-sm:h-[10rem] max-sm:w-[10rem] max-sm:pt-[2rem] pl-[2rem] pr-[2rem] fhd:h-[34rem] hd:h-[26rem] bg-[#FFE358]/50 z-[2] ${currentLanguage === "ar" && "flex-row-reverse"} m-auto rounded-full`}>
                    <h1 className="font-amatic font-bold text-stroke text-stroke-gray text-[#57BEA4] sm:text-[64px]  mt-auto ml-auto mr-auto ">{t("upla")} <span className="text-[#FB7F0D]">{t("club")}</span></h1>
                    <h1 className="font-amatic font-bold text-stroke text-stroke-gray text-white sm:text-[40px]  ml-auto mr-auto mb-auto">{t("clubOp")}</h1>
                </div>
            </TopImage>
            <div className="flex flex-col w-[50%] max-sm:w-[80%] mx-auto ">
                <h1 className="font-amatic font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[52px] max-sm:text-[16px] text-[#323E48] mx-auto  mt-[6vh]">{t("regesrationForm")}</h1>
                {data && <h1 className="font-amatic font-bold 2k:text-[46px] fhd:text-[40px] hd:text-[34px] text-[#323E48] mx-auto  mt-[6vh]">{data.semesterTitle} {data.semesterDuration}</h1>}
                <img loading="lazy" src={image} className="w-full mt-[6vh] h-[100vh] max-sm:h-[20rem] rounded-[10px]" />
                <div className="p-8 bg-white mt-[6vh]">
                    {/* Free Trial */}
                    <div
                        className={`flex ${currentLanguage === "ar" && "flex-row-reverse"} items-center p-4 rounded-lg cursor-pointer hover:bg-yellow-300 ${checkedItems.trial ? "bg-yellow-300" : "bg-white"}`}
                        onClick={() => handleCheck("trial")}
                    >
                        <input
                            type="checkbox"
                            className={`form-checkbox size-[20px] max-sm:size-[12px] accent-[#58C2A6] mr-4 max-sm:mr-2 transition duration-[300ms] ease-in-out ${checkedItems.trial ? "opacity-100" : "opacity-0"}`}
                            checked={checkedItems.trial}
                            onChange={() => handleCheck("trial")}
                        />
                        <img loading="lazy"
                            src={image}
                            alt="Free Trial"
                            className="fhd:w-[124px] fhd:h-[124px] hd:w-[86px] hd:h-[86px] max-sm:h-[4rem] max-sm:w-[4rem] rounded-md mr-4 max-sm:mr-2"
                        />
                        <div className="flex flex-col flex-grow max-sm:text-[10px]">
                            <h3 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`2k:text-[46px] text-[#323E48] fhd:text-[40px] hd:text-[28px] ${currentLanguage === "ar" && "mr-[2%]"} font-amatic font-semibold`}>
                                {t("trialSession")}
                            </h3>
                            <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`2k:text-[46px] text-[#323E48] ${currentLanguage === "ar" && "mr-[2%]"} fhd:text-[40px] hd:text-[28px] font-amatic`}>
                                {t("offer.Trial")}
                            </p>
                        </div>
                        <div className="font-amatic font-bold text-[#323E48] 2k:text-[46px] max-sm:text-[10px] fhd:text-[40px] hd:text-[28px]">{t("freeTrial.price")}</div>
                    </div>
                    {/* Full Semester */}
                    <div
                        className={`flex ${currentLanguage === "ar" && "flex-row-reverse"} items-center p-4 rounded-lg cursor-pointer hover:bg-yellow-300 ${checkedItems.full ? "bg-yellow-300" : "bg-white"}`}
                        onClick={() => handleCheck("full")}
                    >
                        <input
                            type="checkbox"
                            className={`form-checkbox size-[20px] max-sm:size-[12px] accent-[#58C2A6] mr-4 max-sm:mr-2 transition duration-[300ms] ease-in-out ${checkedItems.full ? "opacity-100" : "opacity-0"}`}
                            checked={checkedItems.full}
                            onChange={() => handleCheck("full")}
                        />
                        <img loading="lazy"
                            src={image}
                            alt="Full Semester"
                            className="fhd:w-[124px] fhd:h-[124px] hd:w-[86px] hd:h-[86px] max-sm:h-[4rem] max-sm:w-[4rem] rounded-md mr-4 max-sm:mr-2"
                        />
                        <div className="flex flex-col flex-grow max-sm:text-[10px]">
                            <h3 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`2k:text-[46px] text-[#323E48] fhd:text-[40px] hd:text-[28px] ${currentLanguage === "ar" && "mr-[2%]"} font-amatic font-semibold`}>{t("fullSemeseter")}</h3>
                            <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`2k:text-[46px] text-[#323E48] ${currentLanguage === "ar" && "mr-[2%]"} fhd:text-[40px] hd:text-[28px] font-amatic`}>{t("timeFrom")}</p>
                        </div>
                        <div className="font-amatic font-bold text-[#323E48] 2k:text-[46px] max-sm:text-[10px] fhd:text-[40px] hd:text-[28px]">{t("semesterPrice")}</div>
                    </div>

                    {/* Divider */}
                    {/* <hr className="my-4" /> */}

                    {/* Registration Fee */}
                    {/* {!isRegistred && <div
                        className={`flex ${currentLanguage === "ar" && "flex-row-reverse"} items-center p-4 rounded-lg cursor-pointer hover:bg-yellow-300 ${checkedItems.registration ? "bg-yellow-300" : "bg-white"}`}
                        onClick={() => handleCheck("registration")}
                    >
                        <input
                            type="checkbox"
                            className={`form-checkbox size-[20px] max-sm:size-[12px] accent-[#58C2A6] mr-4 max-sm:mr-2 transition duration-[300ms] ease-in-out ${checkedItems.full ? "opacity-100" : "opacity-0"}`}
                            checked={checkedItems.registration}
                            onChange={() => handleCheck("registration")}
                        />
                        <img loading="lazy"
                            src={image}
                            alt="Registration Fee"
                            className="fhd:w-[124px] fhd:h-[124px] hd:w-[86px] hd:h-[86px] max-sm:h-[4rem] max-sm:w-[4rem] rounded-md mr-4 max-sm:mr-2"
                        />
                        <div className="flex flex-col flex-grow max-sm:text-[10px]">
                            <h3 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`2k:text-[46px] text-[#323E48] fhd:text-[40px] hd:text-[28px] ${currentLanguage === "ar" && "mr-[2%]"} font-amatic font-semibold`}>{t("registrationFee")}</h3>
                            <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`2k:text-[46px] text-[#323E48] ${currentLanguage === "ar" && "mr-[2%]"} fhd:text-[40px] hd:text-[28px] font-amatic`}>{t("registrationFeeDescription")}</p>
                        </div>
                        <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic font-bold text-[#323E48] 2k:text-[46px] max-sm:text-[10px] fhd:text-[40px] hd:text-[28px]">{t("registrationPrice")}</div>
                    </div>} */}

                    {/* Divider */}
                    {/* {!isRegistred && <hr className="my-4" />} */}

                    {/* One Session */}
                    {/* <div
                        className={`flex ${currentLanguage === "ar" && "flex-row-reverse"} items-center p-4 rounded-lg cursor-pointer hover:bg-yellow-300 ${checkedItems.one ? "bg-yellow-300" : "bg-white"}`}
                        onClick={() => handleCheck("one")}
                    >
                        <input
                            type="checkbox"
                            className={`form-checkbox size-[20px] max-sm:size-[12px] accent-[#58C2A6] mr-4 max-sm:mr-2 transition duration-[300ms] ease-in-out ${checkedItems.full ? "opacity-100" : "opacity-0"}`}
                            checked={checkedItems.one}
                            onChange={() => handleCheck("one")}
                        />
                        <img loading="lazy"
                            src={image}
                            alt="One Session"
                            className="fhd:w-[124px] fhd:h-[124px] hd:w-[86px] hd:h-[86px] max-sm:h-[4rem] max-sm:w-[4rem] rounded-md mr-4 max-sm:mr-2"
                        />
                        <div className="flex flex-col flex-grow max-sm:text-[10px]">
                            <h3 dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`2k:text-[46px] text-[#323E48] fhd:text-[40px] hd:text-[28px] ${currentLanguage === "ar" && "mr-[2%]"} font-amatic font-semibold`}>{t("oneSession")}</h3>
                            <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`2k:text-[46px] text-[#323E48] ${currentLanguage === "ar" && "mr-[2%]"} fhd:text-[40px] hd:text-[28px] font-amatic`}>{t("sessionDescription")}</p>
                        </div>
                        {checkedItems.one && (
                            <input
                                type="number"
                                className={`bg-yellow-200 shadow-lg w-[50px] mr-[25px] text-center ${currentLanguage === "ar" && "ml-[2%]"}`}
                                min="1"
                                value={numSessions}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => setNumSessions(e.target.value)}
                            />
                        )}
                        <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic font-bold text-[#323E48] 2k:text-[46px] max-sm:text-[10px] fhd:text-[40px] hd:text-[28px]">{t("sessionPrice")}</div>
                    </div> */}

                    {/* Total Information */}
                    <div className={`mt-8 `}>
                        <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic font-bold text-[#323E48] 2k:text-[46px] fhd:text-[40px] hd:text-[28px]">
                            {t("unitPrice")}
                            <strong dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-normal font-amatic 2k:text-[46px] fhd:text-[40px] hd:text-[28px] ml-[4%]">
                                {checkedItems.full ? `${currentLanguage === "ar" ? " 2100 درهم  " : " 2100 MAD"}` :
                                    (checkedItems.registration && checkedItems.one ? `${!isRegistred ? ` ${numSessions} * 300 ${t("currency")} + 250 ${t("currency")}` : `${numSessions} * 300 MAD`}` :
                                        (isRegistred ? "" : checkedItems.registration ? ` 250 ${t("currency")}` :
                                            (checkedItems.one ? ` ${numSessions} * 300 ${t("currency")}` : `${feePrice} ${t("currency")}`)))}
                                {checkedItems.trial && isRegistred && `${feePrice} ${t("currency")}`}
                            </strong>
                        </p>
                        <p dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-amatic font-bold text-[#323E48] 2k:text-[46px] fhd:text-[40px] hd:text-[28px]">
                            {t("total")}
                            <span dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="font-normal font-amatic 2k:text-[46px] fhd:text-[40px] hd:text-[28px] ml-[14%]">
                                {feePrice} {t("currency")}
                            </span>
                        </p>
                    </div>
                </div>
                <Button
                    title="Submit"
                    className={`z-[2] ml-auto mr-auto mt-[5vh] w-[10vw] max-sm:w-[9rem] amx-sm:text-[10px] rounded-full tracking-[10px] ${shake && 'animate-shake'} `} onClick={handleSubmit}
                />
            </div>
            <Footer />
            <CopyRight />
        </div>
    )
}