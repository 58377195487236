import image1 from "./DSC_8472.jpg"
import image2 from "./DSC_8526.jpg"
import image4 from "./DSC_8772.jpg"
import image5 from "./DSC_8775.jpg"
import image6 from "./DSC_8777.jpg"
import image7 from "./DSC_8782.jpg"
import image8 from "./DSC_8784.jpg"
import image9 from "./DSC_8785.jpg"
import image10 from "./DSC_9000.jpg"
import image11 from "./DSC_9002.jpg"
import image12 from "./DSC_9005.jpg"
import image13 from "./DSC_9008.jpg"
import image14 from "./DSC_9011.jpg"
import image15 from "./DSC_9014.jpg"
import image16 from "./DSC_9012.jpg"
import image17 from "./DSC_9020.jpg"
import image18 from "./DSC_9022.jpg"

export const images = [image1, image2 , image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18];