import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SpecialOffers from "../utils/components/SpecialOffers";
import NavigationBar from "../utils/components/navigation/NavigationBar";
import Footer from "../utils/components/footer/Footer";
import CopyRight from "../utils/components/footer/CopyRight";
import logo from "../assets/character/2.png";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();

  useEffect(() => {
    // Set the document title or trigger server-side status code handling
    document.title = "404 - Page Not Found";
    // You can trigger additional logic here if using server-side rendering
  }, [location]);

  return (
    <div>
      <SpecialOffers />
      <NavigationBar />
      <div className="w-full h-[80vh] flex justify-center items-center flex-col">
        <img loading="lazy" src={logo} className="h-[60%]" alt="Page not found" />
        <h1
          dir={currentLanguage === "ar" ? "rtl" : "ltr"}
          className={`text-[#323E48] font-bold ${
            currentLanguage === "en" ? "font-amatic" : "font-nokta"
          } text-[64px] max-sm:text-[24px]`}
        >
          {t("pagenotfound")}
        </h1>
      </div>
      <Footer />
      <CopyRight />
    </div>
  );
}
