import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import chaimaeImage from "../../../assets/gallery/Chaimae.jpg"
import majidaImage from "../../../assets/gallery/Majida.jpg"

export default function OurTeachers() {

    const teachers = ["chaimaeBigoula", "majida"]

    const [currentTeacher, setCurrentTeacher] = useState(0)

    const images = [chaimaeImage,majidaImage]

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    const nextTeacher = () => {
        setCurrentTeacher((prevTeacher) => (prevTeacher + 1) % teachers.length);
    };

    const prevTeacher = () => {
        setCurrentTeacher((prevTeacher) =>
            prevTeacher === 0 ? teachers.length - 1 : prevTeacher - 1
        );
    };


    return (
        <div className={`flex flex-row max-sm:flex-col relative mt-[4vh] max-sm:mt-[2vh] w-[90%] mx-auto ${currentLanguage === "ar" && "flex-row-reverse"}`}>
            <img loading="lazy" alt="upla arabic club teachers" src={images[currentTeacher]} className={`mt-[2vh] max-sm:mx-auto 2k:h-[880px]  fhd:h-[670px]  hd:h-[460px] max-sm:w-[14rem]  z-[-1]  ${currentLanguage === "en" ? "ml-[2%]" : "mr-[2%]"}`} />
            <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className={`flex flex-col max-sm:mt-[1rem] ${currentLanguage === "en" ? "ml-[2%] mr-[2%]" : "mr-[2%] ml-[2%]"} mt-auto mb-auto `}>
                <h1 className={`text-[#323E48] text-justify 2k:text-[72px] fhd:text-[60px] ml-[2%] hd:text-[48px] max-sm:text-[16px] hover:text-[#FB7F0D] cursor-default w-fit-content font-bold ${currentLanguage==="ar" ? "font-nokta" : "font-amatic"}`}>{t(teachers[currentTeacher])}</h1>
                <p className="text-[#323E48] text-justify font-amatic 2k:text-[32px] fhd:text-[26px] mt-[2%] ml-[2%] hd:text-[18px] max-sm:text-[10px]"><Trans i18nKey={t(teachers[currentTeacher] + ".role")} /></p>
                <p className="text-[#323E48] text-justify font-amatic 2k:text-[32px] fhd:text-[26px] mt-[2%] ml-[2%] hd:text-[18px] max-sm:text-[10px]"><Trans i18nKey={t(teachers[currentTeacher] + ".qualifications")} /></p>
                <p className="text-[#323E48] text-justify font-amatic 2k:text-[32px] fhd:text-[26px] mt-[2%] ml-[2%] hd:text-[18px] max-sm:text-[10px]"><Trans i18nKey={t(teachers[currentTeacher] + ".specialization")} /></p>
                <p className="text-[#323E48] text-justify font-amatic 2k:text-[32px] fhd:text-[26px] mt-[2%] ml-[2%] hd:text-[18px] max-sm:text-[10px]"><Trans i18nKey={t(teachers[currentTeacher] + ".philosophy")} /></p>
                <p className="text-[#323E48] text-justify font-amatic 2k:text-[32px] fhd:text-[26px] mt-[2%] ml-[2%] hd:text-[18px] max-sm:text-[10px]"><Trans i18nKey={t(teachers[currentTeacher] + ".experience")} /></p>
                <div dir={currentLanguage === "ar" ? "rtl" : "ltr"} className="flex sm:gap-x-[50vw] max-sm:gap-x-[8rem] absolute bottom-[-15%] max-sm:bottom-[-8%] left-[25%] max-sm:left-[15%]">
                    <button
                        className="bg-[#FB7F0D] text-white px-4 py-2 max-sm:text-[10px] max-sm:rounded-md rounded-lg"
                        onClick={prevTeacher}
                        disabled={teachers.length <= 1}
                    >
                        {t("previous")}
                    </button>
                    <button
                        className="bg-[#FB7F0D] text-white px-4 py-2 max-sm:text-[10px] max-sm:rounded-md rounded-lg"
                        onClick={nextTeacher}
                        disabled={teachers.length <= 1}
                    >
                        {t("next")}
                    </button>
                </div>
            </div>
        </div>
    )
}