import './Table.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Table() {

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/customerNote/getAllCustomerNotes`, {
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'X-UID': sessionStorage.getItem("uid")
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, []);


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const navigate = useNavigate();

  const nextPage = () => {
    if (currentPage < Math.ceil(data.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRowClick = (id) => {
    navigate(`/admin/note/${id}`);
  };

  return (
    <div className='m-auto '>
      <div className="bare">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      </div>
      <table id="personalInfoTable" dir={currentLanguage === "ar" ? "rtl" : "ltr"}>
        <thead>
          <tr >
            <th className='whitespace-nowrap'>{t("name")}</th>
            <th className='whitespace-nowrap max-w-[50rem]'>{t("note")}</th>
            <th className='whitespace-nowrap'>{t("rating")}</th>
            <th dir={currentLanguage === "ar" ? "rtl" : "ltr"} className='whitespace-nowrap'>{t("approved")}?</th>

          </tr>
        </thead>
        <tbody id="tableBody">
          {currentItems.map((item, index) => (
            <tr key={index} onClick={() => handleRowClick(item.id)} style={{ cursor: 'pointer' }}>
              <td className="whitespace-nowrap">{item.customerName ? item.customerName : t("anonymous")}</td>
              <td className="overflow-ellipsis overflow-hidden whitespace-nowrap max-w-[50rem]">{item.customerText}</td>
              <td>{item.customerRating}</td>
              <td className={`whitespace-nowrap ${item.approved ? "text-green-400" : "text-red-400"}`}>{item.approved ? t("approved") : t("notApproved")}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button
          className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={prevPage}
          style={{ cursor: currentPage === 1 ? 'default' : 'pointer' }}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          className={`pagination-button ${currentPage >= Math.ceil(data.length / itemsPerPage) ? 'disabled' : ''}`}
          onClick={nextPage}
          style={{ cursor: currentPage >= Math.ceil(data.length / itemsPerPage) ? 'default' : 'pointer' }}
          disabled={currentPage >= Math.ceil(data.length / itemsPerPage)}
        >
          Next
        </button>
      </div>
    </div>
  );
}
