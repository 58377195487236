import React from "react";
import SharedPage from "./SharedPage";
import Table from "../utils/admin/table/Table";

export default function AdminFormPage() {

    return (
        <SharedPage>
            <Table/>
        </SharedPage>
    )
}