import { useContext } from "react";
import FormContext from "./form.context";

const useFormContext = () => {
    const {
        currentForm,
        setCurrentForm,
    } = useContext(FormContext)

    return {
        currentForm,
        setCurrentForm,
    }
}

export default useFormContext