import React from "react";
import { useTranslation } from "react-i18next";

export default function Titles({color,title,margin}) {


    const { i18n } = useTranslation()

    const currentLanguage = i18n.language;

    return(
        <div className={`w-[86%] flex flex-row ml-auto mr-auto ${margin}`}>
            <div className={`w-full h-[1px] mt-auto mb-auto bg-[${color}]`}/>
            <h1 className={` font-amatic  fhd:text-[64px] hd:text-[56px] text-[${color}] font-bold ml-[2%] whitespace-nowrap mr-[2%]`}>{title}</h1>
            <div className={`w-full h-[1px] mt-auto mb-auto bg-[${color}]`}/>
        </div>
    )
}