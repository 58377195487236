import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function SpecialOffers() {

  const { t, i18n } = useTranslation();

  const textColor = "#323E48";

  const currentLanguage = i18n.language;

  const [offersList] = useState([
    "offer.Trial",
    "offer.Semester",
    
  ]);
  const [currentOffer, setCurrentOffer] = useState(0);
  const [animateEntering, setAnimateEntering] = useState(false);
  const [animateExiting, setAnimateExiting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimateExiting(true);
    }, 9999);
    return () => clearInterval(interval);
  }, [offersList.length]);

  useEffect(() => {
    if (animateExiting) {
      const timeout = setTimeout(() => {
        setAnimateExiting(false);
        setCurrentOffer(prevOffer => (prevOffer + 1) % offersList.length);
        setAnimateEntering(true);
      }, 3000); 
      return () => clearTimeout(timeout);
    }
  }, [animateExiting]);

  useEffect(() => {
    if (animateEntering) {
      const timeout = setTimeout(() => setAnimateEntering(false), 3000); 
      return () => clearTimeout(timeout);
    }
  }, [animateEntering]);

  return (
    <div className={`2k:h-[66px] fhd:h-[54px] hd:h-[42px] w-full flex bg-[#FE9D2E] overflow-hidden`}>
      <h1 dir={currentLanguage === "ar" ? "rtl" : "ltr"}
        className={`2k:text-[28px] fhd:text-[24px] hd:text-[18px] max-sm:text-[14px] ${currentLanguage === "ar" && "2k:text-[24px] fhd:text-[20px] hd:text-[14px] max-sm:text-[10px]"} mt-auto font-amatic font-bold mb-auto mr-auto ml-auto text-[${textColor}] ${animateEntering ? 'slide-in' : ''} ${animateExiting ? 'slide-out' : ''}`}
      >
        {t(offersList[currentOffer])}
      </h1>
    </div>
  );
}
