import React, { useState, useRef, useEffect } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import video from "../../../assets/club/teachers/مهمتنا.mp4";

export default function TeacherVideo() {
    const [selectedImage, setSelectedImage] = useState(null);
    const smallVideoRef = useRef(null);
    const largeVideoRef = useRef(null);
    const [isVideoInView, setIsVideoInView] = useState(false);

    const [isMobile,setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width < 640);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVideoInView(true);
                    } else {
                        setIsVideoInView(false);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (smallVideoRef.current) {
            observer.observe(smallVideoRef.current);
        }

        return () => {
            if (smallVideoRef.current) {
                observer.unobserve(smallVideoRef.current);
            }
        };
    }, []);

    const handleClick = () => {
        if (smallVideoRef.current) {
            setSelectedImage(video);
        }
    };

    const handleLoadedMetadata = () => {
        if (selectedImage && largeVideoRef.current) {
            largeVideoRef.current.currentTime = smallVideoRef.current.currentTime;
            largeVideoRef.current.play();
        }
    };

    useEffect(() => {
        if (smallVideoRef.current) {
            smallVideoRef.current.muted = !isVideoInView;
        }
    }, [isVideoInView]);

    return (
        <div className="w-[20vw] h-[80vh] max-sm:w-[70vw] max-sm:h-[60vh] mx-auto max-sm:mt-[2vh] max-sm:mb-[2vh] mt-[10vh] mb-[10vh]">
            <video
                className="mt-[2%] w-full h-full object-cover rounded-[10px] cursor-pointer"
                src={video}
                ref={smallVideoRef}
                alt="placeholder"
                onClick={handleClick}
                autoPlay={!isMobile}
                controls={isVideoInView && isMobile}
                loop
                muted={!isVideoInView}
                onCanPlayThrough={(e) => e.target.play()}
            />
            {selectedImage && !isMobile && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[30] bg-black bg-opacity-50">
                    {selectedImage.endsWith('.mp4') ? (
                        <video
                            ref={largeVideoRef}
                            src={selectedImage}
                            alt="Selected"
                            className="w-[25%] h-[70%] max-sm:h-[190%] max-sm:w-[70%] z-[30] opacity-100 object-contain"
                            controls
                            loop
                            autoPlay
                            onLoadedMetadata={handleLoadedMetadata}
                        />
                    ) : (
                        <div />
                    )}
                    <button
                        className="absolute top-0 right-0 m-4 text-red-900 text-[54px]"
                        onClick={() => setSelectedImage(null)}
                    >
                        <IoMdCloseCircle />
                    </button>
                </div>
            )}
        </div>
    );
}
