import React from "react";
import { useTranslation } from "react-i18next";

export default function LocationInfo() {

    const { t, i18n } = useTranslation();

    const currentLanguage = i18n.language;

    return (
        <div className={`flex flex-row w-[70%] max-sm:w-[80%] mx-auto mt-[10vh] max-sm:mt-[2vh] ${currentLanguage==="ar" ? "font-nokta" : "font-amatic"} ${currentLanguage==="ar" && "flex-row-reverse"}`}>
            <h1 className={`text-[#323E48] font-bold 2k:text-[76px] fhd:text-[64px] hd:text-[44px] max-sm:text-[16px] ${currentLanguage==="ar" ?"ml-auto" : "mr-auto"}`}>
                {t("school")}
            </h1>
            <p className={`text-[#323E48] font-bold 2k:text-[76px] fhd:text-[64px] hover:text-[#FB7F0D] cursor-pointer hd:text-[44px] max-sm:text-[16px] ${currentLanguage==="ar" ?"mr-auto" : "ml-auto"}`}>
            <a href={"https://www.google.com/maps/place/ELITE+SCHOOL/@33.9406719,-6.8883661,17z/data=!3m1!4b1!4m6!3m5!1s0xda713d11e31ace5:0x3b7333127a86ad04!8m2!3d33.940672!4d-6.8837527!16s%2Fg%2F11h6r_q54y?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"} target="_blank" rel="noopener noreferrer">{t("locationAddress")}</a>
            </p>
        </div>
    )
}